import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Grid, Card } from '@upperhand/playmaker';

import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import ChatBubbleIcon from 'shared/components/icons/ChatBubble.jsx';
import LinkIcon from 'shared/components/icons/LinkIcon.jsx';
import MessagingDrawerActions from 'shared/actions/MessagingDrawerActions.js';
import EventMarketingActions from 'event_mgmt/display/actions/EventMarketingActions';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { customerScopedRoute } from 'shared/utils/RouteUtils.js';
import { t } from 'shared/utils/LocaleUtils.js';
import { downloadFileFromURL } from 'shared/utils/SharedUtils.js';
import { toSlug } from 'shared/utils/StringUtils.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  link: {
    cursor: 'pointer',
  },
};

function EventNav({ event, isClient, intl }) {
  const navigate = useNavigate();
  const isTeamEvent = event.isTeamEvent();
  const isCoach = currentUser().isCoach();
  const isPermittedEdit = currentUser().isPermittedEdit('events');

  if (typeof event === 'undefined') {
    return <div />;
  }

  function drawerOpened() {
    MessagingDrawerActions.eventDrawerOpened(event);
  }

  function marketingDialogOpened() {
    EventMarketingActions.formOpened();
  }

  const menuOptions =
    isClient || !isPermittedEdit
      ? undefined
      : [
          {
            label: t('.send_email', intl, __filenamespace),
            onClick: () => {
              marketingDialogOpened();
            },
          },
          {
            label: t('.export_attendance', intl, __filenamespace),
            onClick: () => {
              downloadFileFromURL({
                url: `events/${event.id}/attendance_export_csv`,
                fileName: `attendance-${toSlug(
                  `${event.id}-${event.title}`
                )}.csv`,
              });
            },
          },
        ];

  if (!isCoach && !isTeamEvent && isPermittedEdit) {
    menuOptions?.push({
      label: t('.edit_event', intl, __filenamespace),
      onClick: () => {
        navigate(`${event.path}/edit`);
      },
    });
  }

  if (!isCoach && isTeamEvent && isPermittedEdit) {
    menuOptions?.push({
      label: t('.edit_team', intl, __filenamespace),
      onClick: () => {
        navigate(`${event.path}/edit`);
      },
    });
  }

  return (
    <Card
      contentDirection="row"
      menuOptions={menuOptions}
      rootStyle={{ background: 'var(--color-bg-gray)' }}
    >
      <Grid
        alignItems="center"
        container
        item
        justify="space-between"
        direction="row"
        wrap="nowrap"
      >
        <Grid item>
          <Link to={customerScopedRoute(isTeamEvent ? '/teams' : '/events')}>
            <Grid
              item
              container
              direction="row"
              wrap="nowrap"
              alignItems="center"
            >
              <ChevronLeftIcon sx={{ color: uhColors.activeBlue }} />
              {t(isTeamEvent ? '.team' : '.event', intl, __filenamespace)}
            </Grid>
          </Link>
        </Grid>
        {!isClient && isPermittedEdit && (
          <Grid container item justify="flex-end" direction="row" wrap="nowrap">
            <Grid item>
              <IconButton
                onClick={() => {
                  drawerOpened();
                }}
                style={styles.link}
              >
                <ChatBubbleIcon color={uhColors.pmIconGray} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton href={event.get('url')} target="blank">
                <LinkIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() =>
                  downloadFileFromURL({
                    url: `events/${event.id}/registrations_csv`,
                    fileName: `registrations-${toSlug(
                      `${event.id}-${event.title}`
                    )}.csv`,
                  })
                }
              >
                <CloudDownloadIcon
                  sx={{
                    color: uhColors.pmIconGray,
                    width: '24px',
                    height: '24px',
                  }}
                />
              </IconButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Card>
  );
}

export default injectIntl(EventNav);

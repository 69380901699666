import React from 'react';
import { fromJS, List } from 'immutable';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { grey } from '@mui/material/colors';

import TeamLink from 'team_mgmt/shared/components/_TeamLink.jsx';
import PricingAutomationCollection from 'shared/records/PricingAutomationCollection.jsx';

import uhTheme from '_uh_theme.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { formatCurrency } from 'shared/utils/FormattingUtils.jsx';
import { boldText } from 'shared/styles/uhStyles.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';

const styles = {
  title: {
    ...boldText,
  },
  eventType: {
    fontSize: '12px',
    color: uhTheme.palette.inactiveTabTextColor,
  },
  dateRange: {
    fontSize: '13px',
  },
  location: {
    fontSize: '13px',
  },
  teamType: {
    color: uhTheme.palette.inactiveTabTextColor,
  },
  status: {
    fontSize: '12px',
    marginBottom: '5px',
    color: uhTheme.palette.inactiveTabTextColor,
  },
  clientsLabel: {
    fontSize: '13px',
    width: '100px',
  },
  clientsCount: {
    ...boldText,
    fontSize: '13px',
  },
  priceLabel: {
    fontSize: '13px',
    width: '100px',
  },
  priceValue: {
    ...boldText,
    fontSize: '13px',
  },
  cardStyle: {
    marginTop: '1em',
    marginBottom: '1em',
    padding: '10px',
    borderLeftStyle: 'solid',
    borderLeftWidth: 8,
    textTransform: 'capitalize',
    fontSize: '15px',
    color: uhTheme.palette.headerGrey,
    lineHeight: '18px',
  },
  privateLabel: {
    backgroundColor: grey[800],
    color: 'white',
    padding: '2px',
    borderRadius: '2px!important',
    textTransform: 'uppercase',
    fontSize: '0.7em',
    fontWeight: 600,
    width: '60px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkBox: {
    marginLeft: '-9px',
    marginTop: '-7px',
  },
};

const getPrice = (team, automations) => {
  const atds = automations.descriptions.filter(atd => atd.event_id === team.id);
  const collection = PricingAutomationCollection.create(atds);
  if (collection.getNumberOfPricingOptions() > 0) {
    return collection.asText();
  }
  return formatCurrency(team.get('base_price') || 0);
};

function PrivateLabel() {
  return (
    <Paper sx={styles.privateLabel} zDepth={0}>
      <FormattedMessage id={messageId('.private', __filenamespace)} />
    </Paper>
  );
}

function TeamCard({
  team,
  onSelect,
  isTeamSelected,
  allowDeleteTeams,
  clientView,
  automations,
}) {
  const participantsCount = team.get('registered_client_ids').size;
  const rosterSize = team.getIn(['team_detail', 'roster_size'], 0);
  const isPermittedEdit = currentUser().isPermittedEdit('events');

  return (
    <Paper
      sx={{ ...styles.cardStyle, borderLeftColor: team.typeColor() }}
      onClick={() => onSelect(true)}
    >
      <Grid container>
        <Grid item container xs={12} wrap="nowrap">
          {!clientView && isPermittedEdit && (
            <Grid item xs="auto">
              <Checkbox
                sx={styles.checkBox}
                onChange={onSelect}
                checked={isTeamSelected}
              />
            </Grid>
          )}
          <Grid item container xs={8}>
            <Grid item xs={12}>
              <Stack>
                <Typography sx={styles.title}>
                  <TeamLink team={team} showDeletedTeams={allowDeleteTeams} />
                </Typography>
                <Typography sx={styles.eventType}>
                  {team.team_type.name}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Stack alignItems="flex-end">
              <Typography sx={styles.status}>{team.status}</Typography>
              {team.privacy && <PrivateLabel />}
            </Stack>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ margin: '5px 0' }} />
        </Grid>
        {!clientView && (
          <Grid item xs={12}>
            <Stack sx={{ paddingTop: '10px' }}>
              <Stack direction="row" spacing={1}>
                <Typography sx={styles.clientsLabel}>
                  <FormattedMessage
                    id={messageId('.roster_size', __filenamespace)}
                  />
                </Typography>
                <Typography sx={styles.clientsCount}>
                  {`${participantsCount}/${rosterSize}`}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Typography sx={styles.priceLabel}>
                  <FormattedMessage id={messageId('.price', __filenamespace)} />
                </Typography>
                <Typography sx={styles.priceValue}>
                  {getPrice(team, automations)}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}

function ContentCards(props) {
  const {
    teams = List(),
    clientView = false,
    automations = { descriptions: List() },
    purchasedViewable = true,
    allowDeleteTeams = false,
    selectedTeams = [],
    setSelectedTeams = () => {},
  } = props;
  const handleTeamSelection = team => {
    const prevSelected = fromJS(selectedTeams).toSet();
    const teamInSelected = prevSelected.find(e => e.get('id') === team.id);
    if (!teamInSelected) {
      setSelectedTeams(prevSelected.add(team).toArray());
    } else {
      setSelectedTeams(prevSelected.remove(teamInSelected).toJS());
    }
  };
  const isTeamSelected = team =>
    !!selectedTeams.find(selectedTeam => selectedTeam.id === team.id);

  return (
    <div>
      {teams.map(team => (
        <TeamCard
          key={team.id}
          team={team}
          onSelect={() => handleTeamSelection(team)}
          isTeamSelected={!clientView && isTeamSelected(team)}
          clientView={clientView}
          automations={automations}
          purchasedViewable={purchasedViewable}
          allowDeleteTeams={allowDeleteTeams}
        />
      ))}
    </div>
  );
}

ContentCards.propTypes = {
  purchasedViewable: PropTypes.bool,
  clientView: PropTypes.bool,
  allowDeleteTeams: PropTypes.bool,
  teams: PropTypes.instanceOf(List),
};

export { ContentCards, TeamCard };

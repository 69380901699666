import React from 'react';
import { Button, Drawer, Dropdown, Grid } from '@upperhand/playmaker';
import { Box } from '@mui/material';

import { t } from 'shared/utils/LocaleUtils';
import { currentUser } from 'shared/utils/UserUtils.jsx';

import EventSessionCreationActions from 'containers/eventSessionCreation/Actions';

import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';
import {
  FILTER_LOCATION_ALL,
  FILTER_RESOURCE_ALL,
  FILTER_STAFF_ALL,
} from '../completeScheduleModal/Store';

const styles = {
  filtersContainer: {
    position: 'relative',
    width: '100%',
    padding: '8px 0',
  },
  dropdownsContainer: {
    position: 'relative',
    width: '100%',
  },
  dropdownsMobileContainer: {
    padding: '50px 8px 8px 8px',
  },
};
function TeamScheduleHeader({
  locationItems,
  locationFilter,
  locationFilterUpdated,
  staffItems,
  staffFilterUpdated,
  staffFilter,
  resourceItems,
  resourceFilter,
  resourceFilterUpdated,
  onToggleViewMode,
  eventId,
  intl,
}) {
  const isClient = currentUser().isClient();
  const isPermittedEdit = currentUser().isPermittedEdit('events');
  const [openDrawer, setOpenDrawer] = React.useState(false);

  if (isClient) {
    return (
      <Button onClick={onToggleViewMode} type="tertiary" fullWidth rounded>
        {t('.view_sessions', intl, __filenamespace)}
      </Button>
    );
  }

  return (
    <ResponsiveElement
      largeScreen={
        <Box sx={styles.filtersContainer}>
          <Box
            sx={{
              ...styles.dropdownsContainer,
              paddingRight: '10px',
            }}
          >
            <Grid
              alignItems="center"
              container
              justify="space-between"
              spacing={1}
              xs={12}
            >
              <Grid item xs={12} sm={4}>
                <Dropdown
                  id="location"
                  name="location"
                  fullWidth
                  items={[
                    { label: 'Location: All', value: FILTER_LOCATION_ALL },
                  ].concat(locationItems)}
                  onChange={e => locationFilterUpdated(e.target.value)}
                  outline={false}
                  rounded
                  value={locationFilter}
                />
              </Grid>
              <Grid item xs={12} sm={isPermittedEdit ? 2 : 3}>
                <Dropdown
                  id="staff"
                  name="staff"
                  fullWidth
                  items={[
                    { label: 'Staff: All', value: FILTER_STAFF_ALL },
                  ].concat(staffItems)}
                  onChange={e => staffFilterUpdated(e.target.value)}
                  outline={false}
                  rounded
                  value={staffFilter}
                />
              </Grid>
              <Grid item xs={12} sm={isPermittedEdit ? 2 : 3}>
                <Dropdown
                  id="resource"
                  name="resource"
                  rounded
                  fullWidth
                  outline={false}
                  items={[
                    { label: 'Resource: All', value: FILTER_RESOURCE_ALL },
                  ].concat(resourceItems)}
                  value={resourceFilter}
                  onChange={e => resourceFilterUpdated(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button
                  onClick={onToggleViewMode}
                  type="tertiary"
                  fullWidth
                  rounded
                >
                  {t('.view_sessions', intl, __filenamespace)}
                </Button>
              </Grid>
              {isPermittedEdit && (
                <Grid item xs={12} sm={2}>
                  <Button
                    rounded
                    fullWidth
                    onClick={() =>
                      EventSessionCreationActions.toggleDrawer({
                        eventId,
                      })
                    }
                  >
                    {t('.create_session', intl, __filenamespace)}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      }
      smallScreen={
        <Box sx={styles.filtersContainer}>
          <Box
            sx={{
              ...styles.dropdownsContainer,
              paddingRight: '10px',
            }}
          >
            <Grid
              alignItems="center"
              container
              justify="space-between"
              spacing={1}
              xs={12}
            >
              <Grid item xs={12}>
                <Button
                  type="tertiary"
                  rounded
                  icon="filter"
                  fullWidth
                  onClick={() => setOpenDrawer(true)}
                >
                  {t('.filters', intl, __filenamespace)}
                </Button>
              </Grid>
              <Grid item xs={isPermittedEdit ? 6 : 12}>
                <Button
                  onClick={onToggleViewMode}
                  type="tertiary"
                  fullWidth
                  rounded
                >
                  {t('.view_sessions', intl, __filenamespace)}
                </Button>
              </Grid>
              {isPermittedEdit && (
                <Grid item xs={6}>
                  <Button
                    rounded
                    fullWidth
                    onClick={() =>
                      EventSessionCreationActions.toggleDrawer({
                        eventId,
                      })
                    }
                  >
                    {t('.create_session', intl, __filenamespace)}
                  </Button>
                </Grid>
              )}

              <Drawer
                className="filter-drawer"
                open={openDrawer}
                title={t('.filters', intl, __filenamespace)}
                onClose={() => setOpenDrawer(false)}
                content={
                  <Grid container xs={12} spacing={1} alignItems="center">
                    <Grid item xs={12}>
                      <Dropdown
                        id="location"
                        name="location"
                        fullWidth
                        items={[
                          {
                            label: 'Location: All',
                            value: FILTER_LOCATION_ALL,
                          },
                        ].concat(locationItems)}
                        onChange={e => locationFilterUpdated(e.target.value)}
                        value={locationFilter}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Dropdown
                        id="staff"
                        name="staff"
                        fullWidth
                        items={[
                          { label: 'Staff: All', value: FILTER_STAFF_ALL },
                        ].concat(staffItems)}
                        onChange={e => staffFilterUpdated(e.target.value)}
                        value={staffFilter}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Dropdown
                        id="resource"
                        name="resource"
                        fullWidth
                        items={[
                          {
                            label: 'Resource: All',
                            value: FILTER_RESOURCE_ALL,
                          },
                        ].concat(resourceItems)}
                        value={resourceFilter}
                        onChange={e => resourceFilterUpdated(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                }
              />
            </Grid>
          </Box>
        </Box>
      }
    />
  );
}

export default TeamScheduleHeader;

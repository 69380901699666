import * as React from 'react';
import { Route } from 'react-router-dom';

import ContactsReportWrapper from 'reporting/index/components/_ContactsReport.jsx';
import InventoryReport from 'reporting/inventory/components/InventoryReport.jsx';
import MonthlyRecurringRevenueReport from 'reporting/monthly_recurring_revenue/components/MonthlyRecurringRevenueReport.jsx';
import OrdersReport from 'containers/reports/ordersReport/OrdersReport.jsx';
import PayrollReport from 'reporting/payroll/components/PayrollReport.jsx';
import RevenueReport from 'reporting/revenue/components/_RevenueReport.jsx';
import SalesDetail from 'reporting/sales_detail/components/SalesDetail.jsx';
import TransactionsLedgerReport from 'containers/reports/transactionsLedgerReport/TransactionsLedgerReport.jsx';
import WaiversReport from 'reporting/waivers/components/WaiversReport.jsx';
import BalancesReport from 'containers/reports/balancesReport/BalancesReport.jsx';
import ModeReport from 'containers/reports/modeReport/ModeReport.jsx';
import DailyCloseOutReport from 'containers/reports/dailyCloseoutReport/DailyCloseOutReport.jsx';
import AuthorizedPageWrapper from 'routes/helpers/AuthorizedPage.jsx';

import {
  isAdmin,
  isStaffAdmin,
  isStaffMemberReportContacts,
  isStaffMemberReportPayroll,
  isStaffMemberReportOrders,
  isStaffMemberReportLedger,
  isStaffMemberReportSales,
  isStaffMemberReportRevenue,
  isStaffMemberReportMembershipAnalytics,
  isStaffMemberReportWaivers,
  isStaffMemberReportInventory,
  isStaffMemberReportBalances,
  isStaffMemberReportCloseOut,
} from 'routes/helpers/routePermissions';

const reportsRoutes = () => (
  <Route path=":customer_id/reports">
    <Route
      path="contacts_report"
      element={
        <AuthorizedPageWrapper
          allowedRoles={[isAdmin, isStaffAdmin, isStaffMemberReportContacts]}
        >
          <ContactsReportWrapper />
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path="payroll"
      element={
        <AuthorizedPageWrapper allowedRoles={[isStaffMemberReportPayroll]}>
          <PayrollReport />
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path="orders"
      element={
        <AuthorizedPageWrapper
          allowedRoles={[isAdmin, isStaffAdmin, isStaffMemberReportOrders]}
        >
          <OrdersReport />
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path="ledger"
      element={
        <AuthorizedPageWrapper
          allowedRoles={[isAdmin, isStaffAdmin, isStaffMemberReportLedger]}
        >
          <TransactionsLedgerReport />
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path="sales_detail"
      element={
        <AuthorizedPageWrapper
          allowedRoles={[isAdmin, isStaffAdmin, isStaffMemberReportSales]}
        >
          <SalesDetail />
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path="revenue_report"
      element={
        <AuthorizedPageWrapper
          allowedRoles={[isAdmin, isStaffAdmin, isStaffMemberReportRevenue]}
        >
          <RevenueReport />
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path="monthly_recurring_revenue_report"
      element={
        <AuthorizedPageWrapper
          allowedRoles={[
            isAdmin,
            isStaffAdmin,
            isStaffMemberReportMembershipAnalytics,
          ]}
        >
          <MonthlyRecurringRevenueReport />
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path="waivers_report"
      element={
        <AuthorizedPageWrapper
          allowedRoles={[isAdmin, isStaffAdmin, isStaffMemberReportWaivers]}
        >
          <WaiversReport />
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path="inventory"
      element={
        <AuthorizedPageWrapper
          allowedRoles={[isAdmin, isStaffAdmin, isStaffMemberReportInventory]}
        >
          <InventoryReport />
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path="balances"
      element={
        <AuthorizedPageWrapper
          allowedRoles={[isAdmin, isStaffAdmin, isStaffMemberReportBalances]}
        >
          <BalancesReport />
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path="intelligence"
      element={
        <AuthorizedPageWrapper allowedRoles={[isAdmin, isStaffAdmin]}>
          <ModeReport />
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path="daily_closeout"
      element={
        <AuthorizedPageWrapper allowedRoles={[isStaffMemberReportCloseOut]}>
          <DailyCloseOutReport />
        </AuthorizedPageWrapper>
      }
    />
  </Route>
);

export default reportsRoutes;

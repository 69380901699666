import { currentUser, isLoggedIn } from 'shared/utils/UserUtils.jsx';

export const isUnauthorized = () => !isLoggedIn();
export const isAdmin = () => currentUser().isAdmin();
export const isStaff = () => currentUser().isStaff();
export const isStaffAdmin = () => currentUser().isStaffAdmin();
export const isManager = () => currentUser().isManager();
export const isClient = () => currentUser().isClient();
export const isInstructor = () => currentUser().isInstructor();
export const isCoach = () => currentUser().isCoach();

export const isStaffMemberDashboard = () =>
  currentUser().isPermittedEdit('dashboard');
export const isStaffMemberEventsEdit = () =>
  currentUser().isPermittedEdit('events');
export const isStaffMemberEventsView = () =>
  currentUser().isPermittedView('events');
export const isStaffMemberEvents = () =>
  isStaffMemberEventsView() || isStaffMemberEventsEdit();
export const isStaffMemberCalendar = () =>
  !currentUser().isRestricted('calendar');
export const isStaffMemberContacts = () =>
  !currentUser().isRestricted('contacts');
export const isStaffMemberResources = () =>
  !currentUser().isRestricted('resources');
export const isStaffMemberMarketing = () =>
  !currentUser().isRestricted('marketing');
export const isStaffMemberReportContacts = () =>
  !currentUser().isRestricted('report_contacts');
export const isStaffMemberReportPayroll = () =>
  !currentUser().isRestricted('report_payroll');
export const isStaffMemberReportOrders = () =>
  !currentUser().isRestricted('report_orders');
export const isStaffMemberReportLedger = () =>
  !currentUser().isRestricted('report_ledger');
export const isStaffMemberReportSales = () =>
  !currentUser().isRestricted('report_sales');
export const isStaffMemberReportRevenue = () =>
  !currentUser().isRestricted('report_revenue');
export const isStaffMemberReportMembershipAnalytics = () =>
  !currentUser().isRestricted('report_membership_analytics');
export const isStaffMemberReportWaivers = () =>
  !currentUser().isRestricted('report_waivers');
export const isStaffMemberReportInventory = () =>
  !currentUser().isRestricted('report_inventory');
export const isStaffMemberReportBalances = () =>
  !currentUser().isRestricted('report_balances');
export const isStaffMemberReportCloseOut = () =>
  !currentUser().isRestricted('report_close_out');

export const transactionAllowedRoles = window.customer
  .enable_staff_member_refund
  ? [isStaff]
  : [isAdmin, isStaffAdmin];

export default {
  isAdmin,
  isStaff,
  isStaffAdmin,
  isManager,
  isClient,
  isInstructor,
  isUnauthorized,
  isCoach,
  isStaffMemberEvents,
  isStaffMemberCalendar,
  isStaffMemberDashboard,
  isStaffMemberEventsEdit,
  isStaffMemberEventsView,
  isStaffMemberContacts,
  isStaffMemberResources,
  isStaffMemberMarketing,
  isStaffMemberReportContacts,
  isStaffMemberReportPayroll,
  isStaffMemberReportOrders,
  isStaffMemberReportLedger,
  isStaffMemberReportSales,
  isStaffMemberReportRevenue,
  isStaffMemberReportMembershipAnalytics,
  isStaffMemberReportWaivers,
  isStaffMemberReportInventory,
  isStaffMemberReportBalances,
  isStaffMemberReportCloseOut,
  transactionAllowedRoles,
};

import * as React from 'react';
import moment from 'moment-timezone';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Card, Grid, Typography } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import ClientProfileDrawerActions from 'containers/clientProfile/components/Drawers/ClientProfileDrawer/Actions';
import UserAvatar from 'shared/components/_UserAvatar.jsx';
import { ClientDataStore } from 'dataStores';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import Gift from 'shared/components/icons/Gift.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { Box } from '@mui/material';
import { smallScreen } from 'shared/utils/DOMUtils';
import ClientActions from 'containers/events/admin/clients/actions';
import RosterTransferDrawerActions from './RosterTransferDrawer/Actions';

const styles = {
  content: {
    paddingLeft: 8,
  },
};

const showClient = (client, eventId) => {
  ClientProfileDrawerActions.mounted({ clientId: client.id, eventId });
};

const removeClient = (client, eventId, isTeamEvent) => {
  if (isTeamEvent) {
    ClientActions.removeClient({ clientId: client.id });
  } else {
    ClientProfileDrawerActions.mounted({
      clientId: client.id,
      eventId,
      openDrawer: false,
    });
    ClientProfileDrawerActions.openScheduleDrawer({ clientId: client.id });
  }
};

const showTransferDrawer = ({ client, eventId, team }) => {
  RosterTransferDrawerActions.toggleTransferDrawer({
    clientId: client.id,
    eventId,
    team,
  });
};
const ClientCard = injectIntl(
  ({ clientId, eventId, clientDataStore: { clients }, intl, isTeamEvent }) => {
    const isMobile = smallScreen();
    const client = clients.get(clientId);
    const isClient = currentUser().isClient();
    const isPermittedEdit = currentUser().isPermittedEdit('events');
    const allowTransfer =
      !currentUser().isCoach() && currentUser().isStaff() && isPermittedEdit;

    if (!client) {
      return null;
    }

    const menuOptions = [
      {
        label: t('.view', intl, __filenamespace),
        onClick: () => showClient(client, eventId),
      },
    ];

    if (isPermittedEdit) {
      menuOptions.push({
        label: t('.remove', intl, __filenamespace),
        onClick: () => removeClient(client, eventId, isTeamEvent),
      });
    }

    if (allowTransfer) {
      menuOptions.push({
        label: t('.transfer', intl, __filenamespace),
        onClick: () => {
          showTransferDrawer({
            client,
            eventId,
            team: isTeamEvent,
          });
        },
      });
    }

    if (isMobile && isTeamEvent) {
      if (isClient) {
        return (
          <Card contentDirection="row">
            <Grid container>
              <Grid item xs={6}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <UserAvatar user={client} />
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="subtitle2">{client.name()}</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Typography>{client.managing_customer_user_name}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Card>
        );
      }
      return (
        <Card contentDirection="row" menuOptions={menuOptions}>
          <Grid container justify="space-between">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <UserAvatar user={client} />
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="subtitle2">{client.name()}</Typography>
                <Typography>{client.managing_customer_user_name}</Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography>
                {client.gender !== null && (
                  <FormattedMessage
                    id={messageId(`.${client.gender}`, __filenamespace)}
                  />
                )}
                {client.gender === null && <span>&mdash;</span>}
              </Typography>
              <Typography>
                {client.age()} &middot;{' '}
                {moment(client.date_of_birth).format(
                  t('dates.full', intl, __filenamespace)
                )}
              </Typography>
              <Typography>{client.phone}</Typography>
            </Box>
          </Grid>
        </Card>
      );
    }
    if (isClient && isTeamEvent) {
      return (
        <Card contentDirection="row">
          <Grid container>
            <Grid item xs={6}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <UserAvatar user={client} />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="subtitle2">{client.name()}</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box>
                <Typography>{client.managing_customer_user_name}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Card>
      );
    }
    return (
      <Card
        contentDirection="row"
        menuOptions={currentUser().isClient() ? null : menuOptions}
      >
        <div style={styles.content}>
          <Grid container alignItems="center" xs={12} spacing={1}>
            <Grid container item alignItems="center" xs={12} md={5}>
              <Grid item>
                <UserAvatar user={client} />
              </Grid>
              <Grid item>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => showClient(client, eventId)}
                  role="presentation"
                >
                  <Typography>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ margin: '0 2px' }}>{client.name()} </p>
                      {client.isTodayBirthDay() && (
                        <Gift color="#4AD669" style={{ margin: '0 5px' }} />
                      )}
                    </div>
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={1}>
              <Typography>
                {client.gender !== null && (
                  <FormattedMessage
                    id={messageId(`.${client.gender}`, __filenamespace)}
                  />
                )}
                {client.gender === null && <span>&mdash;</span>}
              </Typography>
            </Grid>
            <Grid item xs={11} md={2}>
              {client.age()} &middot;{' '}
              {moment(client.date_of_birth).format(
                t('dates.full', intl, __filenamespace)
              )}
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography>{client.managing_customer_user_name}</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography>{client.phone}</Typography>
            </Grid>
          </Grid>
        </div>
      </Card>
    );
  }
);

export default altContainer({
  stores: {
    clientDataStore: ClientDataStore,
  },
})(ClientCard);

import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { getActiveToken } from './AuthUtils';

export const CLIENT = 'client';
export const MANAGER = 'manager';
export const STANDARD = 'standard';
export const INSTRUCTOR = 'instructor';
export const COACH = 'coach';
export const LEAD = 'lead';
export const STAFF_MEMBER = 'staff_member';
export const STAFF_ADMIN = 'staff_admin';
export const USER_ROLES = [MANAGER, INSTRUCTOR, COACH];

const checkPermissions = (permission, permissionKey, user) => {
  // Users with all permissions will have an empty object
  const hasAllPermissions =
    Object.keys(user?.permissions || {}).length === 0 &&
    user.role &&
    user.role !== 'Client';

  if (hasAllPermissions) {
    return true;
  }

  // Check if the permission is a report permission
  if (permissionKey.includes('report_')) {
    const reportsPermissions = user.permissions?.reports;

    return (
      reportsPermissions && reportsPermissions?.[permissionKey] === permission
    );
  }

  // Check if the permission is a regular permission
  return user.permissions?.[permissionKey] === permission;
};

const decoratedUser = user =>
  merge(user, {
    isAdmin: () => user.role === 'Admin',
    isStaffAdmin: () => user.role === 'StaffAdmin',
    isStaffMember: () =>
      user.role === 'StaffMember' && user.user_role === STANDARD,
    isManager: () => user.role === 'StaffMember' && user.user_role === MANAGER,
    isStaff: () =>
      ['Admin', 'StaffAdmin', 'StaffMember'].includes(user.role) &&
      (user.user_role === MANAGER ||
        ![INSTRUCTOR, COACH].includes(user.user_role)),
    isClient: () => user.role === 'Client',
    isInstructor: () =>
      user.role === 'StaffMember' && user.user_role === INSTRUCTOR,
    isCoach: () => user.role === 'StaffMember' && user.user_role === COACH,
    isPermittedView: permissionKey =>
      checkPermissions('view', permissionKey, user),
    isPermittedEdit: permissionKey =>
      checkPermissions('manage', permissionKey, user),
    isRestricted: permissionKey =>
      !checkPermissions('view', permissionKey, user) &&
      !checkPermissions('manage', permissionKey, user),
  });

export const currentUser = () => decoratedUser(window.user) || {};

export const hasAdminPermission = () =>
  currentUser().isAdmin() ||
  currentUser().isStaffAdmin() ||
  currentUser().isManager();

export const hasCustomer = () => currentCustomer() && currentCustomer().id;

// this should be moved into the AuthUtils. (and removed from window)
export const isLoggedIn = () => !!getActiveToken();

export const loginUrl = (redirectPath, redirectHash) => {
  // If we do not remove the hash out of the redirectPath, appending
  // our redirectHash will be swallowed by the hash.
  const whichRedirect = redirectPath || window.location.pathname;
  const hashIndex = whichRedirect.indexOf('#');
  const urlEnd = hashIndex === -1 ? whichRedirect.length : hashIndex;
  const redirect = whichRedirect.substring(0, urlEnd);

  return `/accounts/login?redirect_to=${redirect}${redirectHash || ''}`;
};

export const createAccountUrl = (redirectPath, redirectHash) =>
  `/accounts/new_user?customer_id=${
    hasCustomer() ? currentCustomer().id : ''
  }&redirect_to=${redirectPath || window.location.pathname}${
    redirectHash || ''
  }`;

export const isEmailValid = email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export const mapRoleToType = role => {
  const roleTypeMapping = {
    manager: STAFF_MEMBER,
    instructor: STAFF_MEMBER,
    coach: STAFF_MEMBER,
    lead: CLIENT,
  };

  // Default to the role value if not explicitly mapped
  return roleTypeMapping[role] || role;
};

export const getValidUserRole = role => {
  const validRoles = [MANAGER, INSTRUCTOR, COACH, LEAD];

  // Check if the role is one of the valid roles
  if (validRoles.includes(role)) {
    return role;
  }

  // Return "standard" for all other scenarios
  return STANDARD;
};

import React, { memo, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Card,
  Confirmation,
} from '@upperhand/playmaker';
import PropTypes from 'prop-types';

import EmailTemplateDrawer from 'containers/marketing/emails/components/EmailTemplateDrawer.jsx';
import Paginator from 'shared/components/Paginator.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import EmptyState from 'containers/marketing/components/EmptyState.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';
import { currentUser } from 'shared/utils/UserUtils.jsx';

import MarketingEmailTemplatesActions from 'containers/marketing/emails/Actions';

import { EmailTemplatesDataStore } from 'dataStores';
import MarketingEmailTemplatesStore from 'containers/marketing/emails/Store';

import './styles.scss';

const inlineStyles = {
  spinnerContainerStyle: {
    position: 'relative',
  },
  spinnerWrapperStyle: {
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
  },
};

function MarketingEmails({
  marketingEmailTemplatesStore = {},
  emailTemplatesDataStore = {},
}) {
  useEffect(() => {
    MarketingEmailTemplatesActions.listMarketingEmailTemplates();
  }, []);

  const intl = useIntl();
  const [templateToDelete, setTemplateToDelete] = useState(null);

  const {
    drawerOpen,
    isTemplatePreview,
    template,
    emailTemplatesIds,
    isUpdating,
    isLoading,
    search,
    pagination,
    templatePreview,
    loading,
  } = marketingEmailTemplatesStore;
  const { emailTemplates } = emailTemplatesDataStore;
  const templatesPagination = pagination.get('templates');
  const templateLoading = loading.get('template');
  const templatePreviewLoading = loading.get('templatePreview');
  const isPermittedEdit = currentUser().isPermittedEdit('marketing');

  const handleTemplateChange = (field, value) => {
    MarketingEmailTemplatesActions.handleFieldChange({
      field,
      value,
    });
  };

  const handleDeleteTemplate = () => {
    MarketingEmailTemplatesActions.deleteMarketingEmailTemplate(
      templateToDelete?.id
    );
    setTemplateToDelete(null);
  };

  const getMenuOptions = tmp => {
    if (!isPermittedEdit) return undefined;

    return [
      {
        label: t('.edit', intl, __filenamespace),
        onClick: () =>
          MarketingEmailTemplatesActions.openMarketingEmailTemplateDrawer({
            template: tmp,
          }),
      },
      {
        label: t('.delete', intl, __filenamespace),
        onClick: () => setTemplateToDelete(tmp),
      },
    ];
  };

  return (
    <div className="marketing-emails">
      <div className="marketing-emails__header">
        {isPermittedEdit && (
          <Button
            rounded
            classes={{
              root: 'marketing-emails__create-btn',
              label: 'marketing-emails__btn-label',
            }}
            onClick={() =>
              MarketingEmailTemplatesActions.openMarketingEmailTemplateDrawer(
                {}
              )
            }
          >
            {t('.create', intl, __filenamespace)}
          </Button>
        )}
        <TextField
          fullWidth
          rounded
          value={search}
          placeholder={t('.search_placeholder', intl, __filenamespace)}
          classes={{ root: 'marketing-emails__search' }}
          onChange={e =>
            MarketingEmailTemplatesActions.searchMarketingEmailTemplates(
              e.target.value
            )
          }
        />
      </div>
      <div className="marketing-emails__content">
        <SpinWhileLoading
          isLoading={isLoading}
          spinnerContainerStyle={inlineStyles.spinnerContainerStyle}
          spinnerWrapperStyle={inlineStyles.spinnerWrapperStyle}
        >
          {emailTemplatesIds.size === 0 && <EmptyState intl={intl} />}
          {emailTemplatesIds.size > 0 && (
            <>
              <div className="marketing-emails__table-header">
                <div className="marketing-emails__table-header-content">
                  <Grid container xs={12} alignItems="center" spacing={1}>
                    <Grid item xs={8}>
                      <Typography variant="fieldLabel">
                        {t('.table_name', intl, __filenamespace)}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="fieldLabel">
                        {t('.table_created_by', intl, __filenamespace)}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <Grid container spacing={1}>
                {emailTemplatesIds.map(id => (
                  <Grid item key={id} xs={12}>
                    <Card
                      key={id}
                      classes={{
                        root: 'marketing-emails__template',
                        content: 'marketing-emails__template-content',
                      }}
                      menuOptions={getMenuOptions(emailTemplates.get(id))}
                    >
                      <Grid
                        item
                        container
                        spacing={1}
                        xs={12}
                        className="marketing-emails__wrapper"
                      >
                        <Grid item xs={8}>
                          <Typography
                            variant="body2"
                            className="marketing-emails__template-name"
                          >
                            {emailTemplates.get(id)?.subject}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body2">
                            {emailTemplates.get(id)?.created_by?.name()}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                ))}
              </Grid>
              <Paginator
                showInfo
                className="marketing-emails__paginator"
                pageLimit={0}
                currentPage={templatesPagination.get('page')}
                perPage={templatesPagination.get('perPage')}
                totalCount={templatesPagination.get('totalCount')}
                onPageSelect={MarketingEmailTemplatesActions.handlePageSelect}
              />
            </>
          )}
        </SpinWhileLoading>
      </div>
      <EmailTemplateDrawer
        isLoading={templateLoading || templatePreviewLoading}
        templatePreview={templatePreview}
        isOpen={drawerOpen}
        isTemplatePreview={isTemplatePreview}
        emailTemplate={template}
        disableConfirm={!template.isValid() || isUpdating}
        confirmLabel={t(
          template.id ? '.update_confirm' : '.create_confirm',
          intl,
          __filenamespace
        )}
        onChange={handleTemplateChange}
        onConfirm={MarketingEmailTemplatesActions.createMarketingEmailTemplate}
        onPreview={MarketingEmailTemplatesActions.toggleMarketingEmailPreview}
        onClose={
          MarketingEmailTemplatesActions.closeMarketingEmailTemplateDrawer
        }
        onCancel={
          MarketingEmailTemplatesActions.closeMarketingEmailTemplateDrawer
        }
      />
      <Confirmation
        classes={{
          root: 'marketing-emails-delete-confirmation',
          actions: 'marketing-emails-delete-confirmation__actions',
        }}
        title={t('.delete_confirm_title', intl, __filenamespace)}
        open={Boolean(templateToDelete)}
        hasCheckbox={false}
        confirmButtonLabel={t('.delete_confirm', intl, __filenamespace)}
        cancelButtonLabel={t('.delete_cancel', intl, __filenamespace)}
        onCancel={() => setTemplateToDelete(null)}
        onConfirm={handleDeleteTemplate}
      >
        <Typography variant="body1">
          {t('.delete_confirm_text', intl, __filenamespace, {
            name: templateToDelete?.subject,
          })}
        </Typography>
      </Confirmation>
    </div>
  );
}

MarketingEmails.propTypes = {
  marketingEmailTemplatesStore: PropTypes.object.isRequired,
  emailTemplatesDataStore: PropTypes.object.isRequired,
};

export default compose(
  memo,
  altContainer({
    stores: {
      marketingEmailTemplatesStore: MarketingEmailTemplatesStore,
      emailTemplatesDataStore: EmailTemplatesDataStore,
    },
  })
)(MarketingEmails);

import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TextField } from '@upperhand/playmaker';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import { grey } from '@mui/material/colors';

import debounce from 'lodash.debounce';

import ConfirmationDialog from 'shared/components/ConfirmationDialog.jsx';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import EventListActions from 'event_mgmt/index/actions/EventListActions.jsx';
import FilterActions from 'event_mgmt/index/actions/FilterActions.jsx';
import FilterButton from 'event_mgmt/index/components/_FilterButton.jsx';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';
import { NonTextVerticalDivider } from 'shared/components/VerticalDivider.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { redirectTo } from 'shared/utils/RouteUtils';
import { windowWidth } from 'shared/utils/DOMUtils';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { SCHEDULE_TYPE } from 'event_mgmt/shared/records/CustomerEvent.jsx';

const styles = {
  actionMenuButton: {
    marginLeft: 0,
    marginRight: 10,
    color: uhColors.hintActive,
  },

  actionMenuButtonDisabled: {
    color: uhColors.disabledGrey,
  },

  searchIcon: {
    alignSelf: 'center',
    bottom: '0.25em',
    marginLeft: 52,
    height: 17,
    marginRight: 13,
    width: 20,
  },

  popover: {
    horizontal: 'right',
    vertical: 'top',
  },

  searchTitleHint: {
    fontSize: 20,
    paddingLeft: '1em',
    color: uhColors.hint,
  },

  searchTitleInput: {
    fontSize: 20,
    paddingLeft: '1em',
    color: uhColors.leftNavGrey,
    width: '100%',
  },

  searchTitleUnderline: {
    borderColor: 'white',
    borderSize: 0,
  },

  toggle: {
    alignItems: 'center',
    width: 200,
  },

  toolbar: {
    backgroundColor: 'white',
    padding: windowWidth() < 768 ? 0 : 'inherit',
  },
};

class ContentToolbar extends React.Component {
  state = {
    eventTitle: '',
    deleteConfirmationOpen: false,
    anchorEl: null,
  };

  debouncedEventList = debounce(eventActions => {
    eventActions.list({
      fields: ['participant_count'],
      scheduleType: [SCHEDULE_TYPE.openBooking, SCHEDULE_TYPE.fixedSchedule],
      page: 1,
    });
  }, 600);

  showDeleteConfirmation = () => {
    this.setState({ deleteConfirmationOpen: true });
  };

  hideDeleteConfirmation = () => {
    this.setState({ deleteConfirmationOpen: false });
  };

  handleTextFieldChange = (_e, value) => {
    const { filterActions, eventActions } = this.props;
    this.setState({ eventTitle: value });
    filterActions.updateTitleFilter(value);

    this.debouncedEventList(eventActions);
  };

  handleActions = (status, callback) => {
    const { allowDeleteEvents, selectedEvents } = this.props;

    // NOTE: this uses EventActions instead of this.props.eventActions because the option to
    //       mark something such as completed is only available to staff admins (which use EventActions)
    //       vs. clients (which use ClientEventActions, and it doesn't have these actions).
    const canUpdateStatus = !(status === 'active' && !allowDeleteEvents);
    if (canUpdateStatus) {
      selectedEvents.forEach(event => {
        EventActions.updateEventStore([], event.set('status', status));
      });
      EventActions.updateEventSelection(selectedEvents, status);
    }
    if (typeof callback === 'function') {
      callback();
    }

    if (status === 'deleted') {
      EventListActions.eventDeleted();
    }
  };

  // eslint-disable-next-line class-methods-use-this
  handleClone = id => {
    EventActions.clone(id);
  };

  hasSelectedEvents = () => {
    const { selectedEvents } = this.props;
    return selectedEvents && selectedEvents.length;
  };

  singleSelectionMenuOptions = () => {
    const { intl, selectedEvents, readOnly } = this.props;

    if (selectedEvents.length === 1) {
      const event = selectedEvents[0];
      const menuItems = [
        <MenuItem
          key="1"
          onClick={() => redirectTo({ path: event.admin_path })}
        >
          {t('.view', intl, __filenamespace)}
        </MenuItem>,
      ];
      if (!readOnly) {
        menuItems.push(
          <MenuItem
            key="2"
            onClick={() => redirectTo({ path: `${event.path}/edit` })}
          >
            {t('.edit', intl, __filenamespace)}
          </MenuItem>,
          <MenuItem key="3" onClick={() => this.handleClone(event.id)}>
            {t('.clone', intl, __filenamespace)}
          </MenuItem>
        );
      }
      if (menuItems.length) {
        menuItems.push(<Divider key="divider" />);
      }
      return menuItems;
    }
    return [];
  };

  toggleShowingEnrolledEventsOnly = () => {
    const { showEnrolledOnlyToggle, filterActions, eventActions } = this.props;

    if (showEnrolledOnlyToggle) {
      filterActions.toggleBookedEventsOnly();
      eventActions.list({ fields: ['participant_count'] });
    }
  };

  // eslint-disable-next-line class-methods-use-this
  actionButtonLabel = () => (
    <ResponsiveElement
      largeScreen={
        <span>
          <FormattedMessage id={messageId('.action', __filenamespace)} />
        </span>
      }
      smallScreen={<div />}
    />
  );

  getEventTitle = () => {
    const { selectedEvents } = this.props;

    return selectedEvents.map(event => event.get('title')).join(', ');
  };

  areAllDeleted() {
    const { selectedEvents } = this.props;
    const statuses = selectedEvents.map(event => event.get('status'));
    return statuses.every(status => status === 'deleted');
  }

  render() {
    const {
      intl,
      showEnrolledOnlyToggle,
      allowDeleteEvents,
      bookedEventsOnly,
      showActionMenu,
      filterActions,
    } = this.props;
    const { deleteConfirmationOpen, eventTitle, anchorEl } = this.state;
    const disableActionButton =
      !this.hasSelectedEvents() || (this.areAllDeleted() && !allowDeleteEvents);
    const actionIconColor = disableActionButton
      ? uhColors.disabledGrey
      : uhColors.hintActive;
    const disabledStyles = disableActionButton
      ? styles.actionMenuButtonDisabled
      : {};
    const actionStyles = { ...styles.actionMenuButton, ...disabledStyles };

    return (
      /* TODO Can we access theme colors with variables? */
      <Toolbar style={styles.toolbar}>
        <Stack style={{ width: '60%' }}>
          <TextField
            icon="search"
            fullWidth
            outline={false}
            placeholder={t('.search', intl, __filenamespace)}
            onChange={this.handleTextFieldChange}
            value={eventTitle}
          />
        </Stack>

        <Stack
          spacing={0}
          direction="row"
          style={{
            justifyContent: showEnrolledOnlyToggle
              ? 'space-between'
              : 'flex-end',
            width: '40%',
          }}
        >
          {showEnrolledOnlyToggle && (
            <FlexBoxJustify style={styles.toggle}>
              <div style={{ fontWeight: 600 }}>
                <FormattedMessage id={messageId('.show', __filenamespace)} />
              </div>
              <div>
                <FormattedMessage
                  id={messageId('.enrolled', __filenamespace)}
                />
              </div>
              <Switch
                style={{ width: 'auto' }}
                checked={!bookedEventsOnly}
                onChange={this.toggleShowingEnrolledEventsOnly}
              />
              <div>
                <FormattedMessage id={messageId('.all', __filenamespace)} />
              </div>
            </FlexBoxJustify>
          )}

          {showEnrolledOnlyToggle && (
            <NonTextVerticalDivider height={30} width={2} color={grey[300]} />
          )}

          <ResponsiveElement
            largeScreen={
              <FilterButton
                style={{ width: '7em' }}
                filterActions={filterActions}
                large
              />
            }
            smallScreen={<div />}
          />

          {showActionMenu && currentUser().isPermittedEdit('events') && (
            <>
              <Button
                style={actionStyles}
                disabled={disableActionButton}
                startIcon={<MoreVertIcon sx={{ color: actionIconColor }} />}
                onClick={e => {
                  this.setState({ anchorEl: e.currentTarget });
                }}
              >
                {this.actionButtonLabel()}
              </Button>
              <Menu
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => {
                  this.setState({ anchorEl: null });
                }}
              >
                {this.singleSelectionMenuOptions()}
                <MenuItem
                  key="4"
                  className="cancel-event-button"
                  onClick={() => this.handleActions('cancelled')}
                >
                  {t('.cancel', intl, __filenamespace)}
                </MenuItem>
                <MenuItem
                  key="5"
                  className="complete-event-button"
                  onClick={() => this.handleActions('completed')}
                >
                  {t('.complete', intl, __filenamespace)}
                </MenuItem>
                {this.areAllDeleted() ? (
                  <MenuItem
                    key="6"
                    className="undelete-event-button"
                    onClick={() => this.handleActions('active')}
                  >
                    {t('.undelete', intl, __filenamespace)}
                  </MenuItem>
                ) : (
                  <MenuItem
                    key="7"
                    className="delete-event-button"
                    onClick={this.showDeleteConfirmation}
                  >
                    {t('.delete', intl, __filenamespace)}
                  </MenuItem>
                )}
              </Menu>
            </>
          )}
          <ConfirmationDialog
            awaitingConfirmation={deleteConfirmationOpen}
            onCancel={this.hideDeleteConfirmation}
            onConfirm={() =>
              this.handleActions('deleted', this.hideDeleteConfirmation)
            }
            title={t('.event_title', intl, __filenamespace, {
              event: this.getEventTitle(),
            })}
          >
            <FormattedMessage
              id={messageId('.delete_message', __filenamespace)}
            />
          </ConfirmationDialog>
        </Stack>
      </Toolbar>
    );
  }
}

ContentToolbar.propTypes = {
  filterActions: PropTypes.object,
  eventActions: PropTypes.object,
  showActionMenu: PropTypes.bool,
  showEnrolledOnlyToggle: PropTypes.bool,
  bookedEventsOnly: PropTypes.bool,
  allowDeleteEvents: PropTypes.bool,
  selectedEvents: PropTypes.array,
};

ContentToolbar.defaultProps = {
  filterActions: FilterActions,
  eventActions: EventActions,
  showActionMenu: true,
  showEnrolledOnlyToggle: false,
  bookedEventsOnly: false,
  allowDeleteEvents: false,
  selectedEvents: [],
};

export default injectIntl(ContentToolbar);

export default alt.generateActions(
  'hourlyRateSaveError',
  'hourlyRateSaveSuccess',
  'hourlyRateSaved',
  'hourlyRateUpdated',
  'staffSelected',
  'tabSelected',
  'toggleInfoEditMode',
  'togglePayrollEditMode',
  'addEventRate',
  'removeEventRate',
  'listUnavailabilitySuccess',
  'listUnavailabilityError',
  'reportFullAccess',
  'reportPartialAccess',
  'reportRestrictedAccess',
  'updatePermission',
  'resetPermissions',
  'savePermissions',
  'savePermissionsSuccess',
  'savePermissionsError'
);

import { List, Record, Set, Map } from 'immutable';
import moment from 'moment-timezone';

import Address from 'shared/records/Address.jsx';
import customerUserAttributes from 'shared/utils/CustomerUserUtils.jsx';
import { isCollectionWithContents } from 'shared/utils/ObjectUtils.jsx';
import { formatPhoneToE164 } from 'shared/utils/FormattingUtils.jsx';
import { Preferences, Image } from 'records';

export const STAFF_PERMISSIONS = {
  VIEW: 'view',
  RESTRICTED: 'restricted',
  MANAGE: 'manage',
};

export const PERMISSION_KEYS = {
  DASHBOARD: 'dashboard',
  EVENTS: 'events',
  CALENDAR: 'calendar',
  CONTACTS: 'contacts',
  MARKETING: 'marketing',
  REPORTS: 'reports',
  REPORT_CONTACTS: 'report_contacts',
  REPORT_PAYROLL: 'report_payroll',
  REPORT_ORDERS: 'report_orders',
  REPORT_LEDGER: 'report_ledger',
  REPORT_SALES: 'report_sales',
  REPORT_REVENUE: 'report_revenue',
  REPORT_MEMBERSHIP_ANALYTICS: 'report_membership_analytics',
  REPORT_WAIVERS: 'report_waivers',
  REPORT_INVENTORY: 'report_inventory',
  REPORT_BALANCES: 'report_balances',
  REPORT_CLOSE_OUT: 'report_close_out',
  MEMBERSHIPS: 'memberships',
  CREDIT_PASSES: 'credit_passes',
  RESOURCES: 'resources',
  RETAIL: 'retail',
  COUPONS: 'coupons',
  SETTINGS: 'settings',
  REFUNDS: 'refunds',
  MEMBERSHIP_MANAGEMENT: 'membership_management',
};

export const TWO_CONDITIONS_PERMISSION_KEY = [
  PERMISSION_KEYS.DASHBOARD,
  PERMISSION_KEYS.CONTACTS,
  PERMISSION_KEYS.RESOURCES,
  PERMISSION_KEYS.SETTINGS,
];

export const convertStaffToDataSource = data =>
  data
    .map(s => ({
      text: s.name(),
      value: s.get('id'),
      isAccessRevoked: s.get('access_revoked_at', null),
    }))
    .toList()
    .toJS();

// TODO: refactor like https://github.com/facebook/immutable-js/issues/334#issuecomment-226097016
class Staff extends Record({
  id: null,
  accepted_customer_terms_at: null,
  accepted_upper_hand_terms_at: null,
  age: null,
  address: new Address(),
  date_of_birth: null,
  email: null,
  first_name: null,
  gender: null,
  get_text_notifications: true,
  get_email_notifications: true,
  has_accepted_terms: false,
  hourly_rate: 0,
  last_name: null,
  password: null,
  password_confirmation: null,
  permitted_membership_ids: Set(),
  phone: null,
  preferences: new Preferences(),
  last_login_at: null,
  invite_sent_at: null,
  login_enabled: true,
  profile_image: new Image(),
  ssn_last_4: null,
  user_id: null,
  availability_schedule_id: null,
  type: null,
  access_revoked_at: false,
  permissions: Map(),
  role: 'standard',
}) {
  constructor(object = {}, options = {}) {
    let attrs = customerUserAttributes(object);

    attrs = attrs.set('preferences', new Preferences(object.preferences));

    if (attrs.get('permitted_membership_ids')) {
      attrs = attrs.set(
        'permitted_membership_ids',
        Set(attrs.get('permitted_membership_ids'))
      );
    }

    if (attrs.get('permissions')?.size > 0) {
      const reportsPermissions = attrs
        .get('permissions')
        .filter((value, key) => key.includes('report_'));
      const otherPermissions = attrs
        .get('permissions')
        .filter((value, key) => !key.includes('report_'));

      attrs = attrs
        .set('permissions', Map(otherPermissions))
        .setIn(['permissions', 'reports'], reportsPermissions);
    }
    super(attrs, options);
  }

  alternativeImage(name) {
    if (!this.profile_image) {
      return null;
    }
    return this.profile_image.getAlternative(name);
  }

  isBlank() {
    return !this.isPresent();
  }

  isPresent(iterableCriteria) {
    if (isCollectionWithContents(iterableCriteria)) {
      return List(iterableCriteria).every(criterion => !!this[criterion]);
    }
    return !!(this.first_name || this.last_name || this.id);
  }

  age() {
    return parseInt(moment().diff(this.date_of_birth, 'years', true), 10);
  }

  underThirteen() {
    const age = this.age();
    return Number.isNaN(age) || age < 13;
  }

  name() {
    return `${this.first_name} ${this.last_name}`;
  }

  isManager() {
    return this.role === 'manager';
  }

  isInstructor() {
    return this.role === 'instructor';
  }

  isCoach() {
    return this.role === 'coach';
  }

  async toServer(forCreate) {
    const data = this.toJS();
    if (forCreate) {
      delete data.id;
    }

    if (data.profile_image && !data.profile_image.file) {
      delete data.profile_image;
    } else {
      data.profile_image = await this.profile_image.toServer();
    }

    if (new Address(data.address || {}).isBlank()) {
      delete data.address;
    }

    if (!data.email || !data.email.trim().length) {
      delete data.email;
    }

    data.preferences = this.preferences.toServer();

    if (data.phone) {
      data.phone = formatPhoneToE164(data.phone);
    } else {
      data.phone = null;
    }

    return data;
  }

  typeDisplay() {
    return this.type === 'StaffMember' ? 'staff' : 'admin';
  }

  isInvitePending() {
    return !!this.invite_sent_at && !this.last_login_at;
  }

  // eslint-disable-next-line class-methods-use-this
  isStaff() {
    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  isClient() {
    return false;
  }

  needsToAcceptCustomerTerms(policyUpdatedAt) {
    return (
      policyUpdatedAt &&
      (!this.accepted_customer_terms_at ||
        this.accepted_customer_terms_at < policyUpdatedAt)
    );
  }

  needsToAcceptUpperHandTerms(policyUpdatedAt) {
    return (
      !this.accepted_upper_hand_terms_at ||
      this.accepted_upper_hand_terms_at < policyUpdatedAt
    );
  }

  isArchived() {
    return Boolean(this.access_revoked_at);
  }
}

export default Staff;

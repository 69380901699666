import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography, Grid, Dropdown } from '@upperhand/playmaker';
import DoneIcon from '@mui/icons-material/Done';

import {
  PERMISSION_KEYS,
  STAFF_PERMISSIONS,
  TWO_CONDITIONS_PERMISSION_KEY,
} from 'shared/records/Staff.jsx';

import { t } from 'shared/utils/LocaleUtils';

function MenuItem({ label, item, permission }) {
  const intl = useIntl();
  const isSelected = item.value === permission.value;

  let description = t(`.description_${item.value}`, intl, __filenamespace);

  if (TWO_CONDITIONS_PERMISSION_KEY.includes(permission.key)) {
    description = t(
      `.two_action_description_${item.value}`,
      intl,
      __filenamespace
    );
  }

  if (permission.key === PERMISSION_KEYS.REPORTS) {
    description = t(
      `.reports_description_${item.value}`,
      intl,
      __filenamespace
    );
  }

  return (
    <Grid container spacing={1} alignItems="center" justify="space-between">
      <Grid item xs={10}>
        <Typography variant="fieldLabel">{label}</Typography>
        <Typography variant="body2">{description}</Typography>
      </Grid>
      {isSelected && (
        <Grid item container xs={2} alignItems="center" justify="center">
          <DoneIcon sx={{ color: 'var(--color-green)' }} />
        </Grid>
      )}
    </Grid>
  );
}

MenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  item: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  permission: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
};

function PermissionSelector({ permission, onChange }) {
  const intl = useIntl();

  let options = [
    {
      label: t(`.${STAFF_PERMISSIONS.MANAGE}`, intl, __filenamespace),
      value: STAFF_PERMISSIONS.MANAGE,
    },
    {
      label: t(`.${STAFF_PERMISSIONS.VIEW}`, intl, __filenamespace),
      value: STAFF_PERMISSIONS.VIEW,
    },
    {
      label: t(`.${STAFF_PERMISSIONS.RESTRICTED}`, intl, __filenamespace),
      value: STAFF_PERMISSIONS.RESTRICTED,
    },
  ];

  if (TWO_CONDITIONS_PERMISSION_KEY.includes(permission.key)) {
    options = [
      {
        label: t(`.${STAFF_PERMISSIONS.MANAGE}`, intl, __filenamespace),
        value: STAFF_PERMISSIONS.MANAGE,
      },
      {
        label: t(`.${STAFF_PERMISSIONS.RESTRICTED}`, intl, __filenamespace),
        value: STAFF_PERMISSIONS.RESTRICTED,
      },
    ];
  }

  if (permission.key === PERMISSION_KEYS.REPORTS) {
    options = [
      {
        label: t(`.reports_${STAFF_PERMISSIONS.MANAGE}`, intl, __filenamespace),
        value: STAFF_PERMISSIONS.MANAGE,
      },
      {
        label: t(`.reports_${STAFF_PERMISSIONS.VIEW}`, intl, __filenamespace),
        value: STAFF_PERMISSIONS.VIEW,
      },
      {
        label: t(
          `.reports_${STAFF_PERMISSIONS.RESTRICTED}`,
          intl,
          __filenamespace
        ),
        value: STAFF_PERMISSIONS.RESTRICTED,
      },
    ];
  }

  return (
    <Dropdown
      fullWidth
      name={permission.key}
      items={options}
      value={permission.value}
      renderMenuItem={(label, item) => (
        <MenuItem label={label} item={item} permission={permission} />
      )}
      onChange={onChange}
    />
  );
}

PermissionSelector.propTypes = {
  permission: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default memo(PermissionSelector);

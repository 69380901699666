import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useIntl } from 'react-intl';
import { OrderedSet, Map } from 'immutable';
import { Grid, Card, Confirmation, Typography } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils';
import { currentUser } from 'shared/utils/UserUtils.jsx';

import { CAMPAIGN_MODES } from 'shared/records/EmailCampaign';

import MarketingEmailCampaignsActions from 'containers/marketing/campaigns/Actions';

function CampaignsList({ emailCampaignsIds, campaigns }) {
  const intl = useIntl();
  const [campaignToDelete, setCampaignToDelete] = useState(null);
  const [campaignToCancel, setCampaignToCancel] = useState(null);
  const isPermittedEdit = currentUser().isPermittedEdit('marketing');

  const handleDelete = () => {
    MarketingEmailCampaignsActions.deleteMarketingCampaign(campaignToDelete.id);
    setCampaignToDelete(null);
  };

  const handleCancel = () => {
    MarketingEmailCampaignsActions.cancelMarketingCampaign(campaignToCancel.id);
    setCampaignToCancel(null);
  };

  return (
    <>
      <Grid container spacing={1}>
        {emailCampaignsIds.map(id => {
          const cmp = campaigns.get(id);
          const scheduledAt = cmp.get('scheduled_at')
            ? moment(cmp.get('scheduled_at')).format('YYYY/MM/DD h:mm a')
            : null;
          const isEditing = ['scheduled', 'draft'].includes(cmp.get('status'));
          const menuOptions = [
            {
              label: isEditing
                ? t('.edit', intl, __filenamespace)
                : t('.view', intl, __filenamespace),
              visible: true,
              onClick: () =>
                MarketingEmailCampaignsActions.openMarketingCampaignDrawer({
                  campaign: cmp,
                  mode: isEditing ? CAMPAIGN_MODES.EDIT : CAMPAIGN_MODES.VIEW,
                }),
            },
            {
              label: t('.delete', intl, __filenamespace),
              visible: true,
              onClick: () => setCampaignToDelete(cmp),
            },
            {
              label: t('.cancel', intl, __filenamespace),
              visible: cmp.get('status') === 'scheduled',
              onClick: () => setCampaignToCancel(cmp),
            },
          ].filter(option => option.visible);

          return (
            <Grid item xs={12} key={id}>
              <Card
                classes={{
                  root: 'marketing-campaigns__campaign',
                  content: 'marketing-campaigns__campaign-content',
                }}
                menuOptions={isPermittedEdit ? menuOptions : undefined}
              >
                <Grid
                  item
                  container
                  spacing={1}
                  xs={12}
                  className="marketing-campaigns__wrapper"
                >
                  <Grid item xs={2}>
                    <Typography variant="body2">{cmp.get('title')}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body2">
                      {cmp.get('email_template').subject}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body2">
                      {cmp.get('contact_group').name}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body2">
                      {moment(cmp.get('created_at')).format(
                        'YYYY/MM/DD h:mm a'
                      )}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    className="marketing-campaigns__campaign-content-status"
                  >
                    <Typography variant="body2">{cmp.get('status')}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body2">{scheduledAt}</Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <Confirmation
        classes={{
          root: 'action-confirmation',
          actions: 'action-confirmation__actions',
        }}
        title={
          campaignToDelete
            ? t('.delete_confirmation', intl, __filenamespace)
            : t('.cancel_confirmation', intl, __filenamespace)
        }
        cancelButtonLabel={t('.no', intl, __filenamespace)}
        confirmButtonLabel={t('.yes', intl, __filenamespace)}
        hasCheckbox={false}
        open={Boolean(campaignToDelete) || Boolean(campaignToCancel)}
        onCancel={() => {
          setCampaignToCancel(null);
          setCampaignToDelete(null);
        }}
        onConfirm={campaignToDelete ? handleDelete : handleCancel}
      >
        {Boolean(campaignToDelete) && (
          <Typography variant="body1">
            {t('.delete_confirmation_text', intl, __filenamespace, {
              name: campaignToDelete.title,
            })}
          </Typography>
        )}
        {Boolean(campaignToCancel) && (
          <Typography variant="body1">
            {t('.cancel_confirmation_text', intl, __filenamespace, {
              name: campaignToCancel.title,
            })}
          </Typography>
        )}
      </Confirmation>
    </>
  );
}

CampaignsList.propTypes = {
  emailCampaignsIds: PropTypes.instanceOf(OrderedSet).isRequired,
  campaigns: PropTypes.instanceOf(Map).isRequired,
};

export default memo(CampaignsList);

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { List } from 'immutable';
import PropTypes from 'prop-types';

import { grey } from '@mui/material/colors';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Checkbox from '@mui/material/Checkbox';

import EventPrice from 'shared/components/EventPrice.jsx';
import EventListActions from 'event_mgmt/index/actions/EventListActions.jsx';
import EventLink from 'event_mgmt/shared/components/_EventLink.jsx';
import Label from 'shared/components/Label.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { currentUser } from 'shared/utils/UserUtils.jsx';

const getStyles = event => {
  const eventTypeColor =
    event && event.event_type ? event.event_type.color : 'white';
  return {
    rowTopText: {
      margin: 0,
      fontWeight: 'bold',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      paddingTop: 3,
    },

    rowBottomText: {
      margin: 0,
      textTransform: 'capitalize',
      paddingTop: 6,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },

    revenueColumn: {
      width: '10rem',
    },

    clientsColumn: {
      width: '10rem',
    },

    eventTitleTypeContainer: {
      display: 'inline-block',
      position: 'relative',
      top: 1,
      left: 5,
      verticalAlign: 'middle',
    },

    eventTitleTypeColorIndicator: {
      backgroundColor: eventTypeColor,
      borderRadius: 2,
      border: 2,
      width: 4,
      marginRight: '0.5em',
      display: 'inline-block',
      position: 'absolute',
      bottom: '0.5em',
      height: '70%',
    },

    privacyColumn: {
      width: '8em',
    },
  };
};

const headerStyles = {
  clientsColumnHeader: {
    width: '10rem',
    fontSize: 15,
  },

  header: {
    fontVariant: 'small-caps',
    fontSize: '16px',
    color: grey[500],
    padding: '16px',
  },

  revenueColumnHeader: {
    width: '10rem',
    fontSize: 15,
  },

  privacyColumnHeader: {
    width: '8em',
    fontSize: 'inherit',
  },
};

class ContentTable extends React.Component {
  handleRowSelection(index, eventId) {
    const { events, selectedEvents } = this.props;

    if (index === 'all') {
      EventListActions.eventsSelected(events);
    } else if (index === 'none') {
      EventListActions.eventsSelected([]);
    } else {
      const isSelected = selectedEvents.find(event => event.id === eventId);

      if (isSelected) {
        const filteredEvents = selectedEvents.filter(
          event => event.id !== eventId
        );
        EventListActions.eventsSelected(filteredEvents);
      } else {
        EventListActions.eventsSelected([...selectedEvents, events.get(index)]);
      }
    }
  }

  isRowSelected(event) {
    const { selectedEvents } = this.props;

    return !!selectedEvents.find(
      selectedEvent => selectedEvent.id === event.id
    );
  }

  render() {
    const { events, allowDeleteEvents, automations, selectedEvents } =
      this.props;
    const { descriptions } = automations;
    const isAllSelected = events.size === selectedEvents.size;

    return (
      <TableContainer>
        <Table size="small" style={{ backgroundColor: 'var(--color-white)' }}>
          <TableHead>
            <TableRow>
              {currentUser().isPermittedEdit('events') && (
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={isAllSelected}
                    onClick={() =>
                      this.handleRowSelection(isAllSelected ? 'none' : 'all')
                    }
                  />
                </TableCell>
              )}

              <TableCell style={headerStyles.header}>
                <FormattedMessage id={messageId('.name', __filenamespace)} />
              </TableCell>
              <TableCell style={headerStyles.header}>
                <FormattedMessage
                  id={messageId('.date_status', __filenamespace)}
                />
              </TableCell>
              <TableCell style={headerStyles.header}>
                <FormattedMessage
                  id={messageId('.location', __filenamespace)}
                />
              </TableCell>
              <TableCell style={headerStyles.header}>
                <FormattedMessage id={messageId('.clients', __filenamespace)} />
              </TableCell>
              <TableCell style={headerStyles.header}>
                <FormattedMessage id={messageId('.price', __filenamespace)} />
              </TableCell>
              <TableCell style={headerStyles.privacyColumnHeader} />
            </TableRow>
          </TableHead>
          <TableBody>
            {events.map((event, index) => {
              const styles = getStyles(event);

              return (
                <TableRow
                  key={event.id}
                  onClick={() => this.handleRowSelection(index, event.id)}
                >
                  {currentUser().isPermittedEdit('events') && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={this.isRowSelected(event)}
                      />
                    </TableCell>
                  )}
                  <TableCell style={{ position: 'relative' }}>
                    <span style={styles.eventTitleTypeColorIndicator} />
                    <p style={merge(styles.rowTopText, { marginLeft: 12 })}>
                      <EventLink
                        event={event}
                        showDeletedEvents={allowDeleteEvents}
                      />
                    </p>
                    <p style={merge(styles.rowBottomText, { marginLeft: 12 })}>
                      {event.event_type ? event.event_type.name : '&ensp;'}
                    </p>
                  </TableCell>
                  <TableCell>
                    <div style={styles.eventTitleTypeContainer}>
                      <p style={styles.rowTopText}>{event.dateRangeText()}</p>
                      <p style={styles.rowBottomText}>{event.status}</p>
                    </div>
                  </TableCell>
                  <TableCell>
                    <p style={styles.rowTopText}>{event.locationName}</p>
                  </TableCell>
                  <TableCell>
                    <p style={styles.rowTopText}>
                      {event.participant_count || '0'}
                    </p>
                  </TableCell>
                  <TableCell>
                    <p style={styles.rowTopText}>
                      <EventPrice event={event} automations={descriptions} />
                    </p>
                  </TableCell>
                  <TableCell>
                    {event.privacy && (
                      <Label style={{ textTransform: 'uppercase' }}>
                        <FormattedMessage
                          id={messageId('.private', __filenamespace)}
                        />
                      </Label>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

ContentTable.propTypes = {
  allowDeleteEvents: PropTypes.bool,
  events: PropTypes.instanceOf(List),
  automations: PropTypes.object,
  selectedEvents: PropTypes.array,
};

ContentTable.defaultProps = {
  allowDeleteEvents: false,
  events: List(),
  automations: {
    descriptions: List(),
  },
  selectedEvents: [],
};

export default ContentTable;

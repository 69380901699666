import * as React from 'react';
import { useIntl } from 'react-intl';

import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';

import { currentUser } from 'shared/utils/UserUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { redirectTo } from 'shared/utils/RouteUtils';
import { smallScreen } from 'shared/utils/DOMUtils';
import { compose } from 'shared/utils/SharedUtils.js';
import altContainer from 'shared/hocs/altContainer.jsx';
import Session from 'shared/records/Session';

import QuickpayModalActions from 'quickpay/actions/QuickpayModalActions.js';
import CalendarActions from 'calendar/actions/CalendarActions.jsx';

import { SessionDataStore } from 'dataStores';

const styles = {
  MenuItem: {
    fontSize: '15px',
    padding: '12px',
  },
  MenuItemLink: {
    display: 'block',
    color: 'inherit',
  },
};

const handleSessionClick = (
  e,
  eventTime,
  handleRequestClose,
  selectedTab = 'details'
) => {
  e.preventDefault();

  const { event_id: eventId, session_id: sessionId } = eventTime;

  CalendarActions.viewSessionClicked({
    eventId,
    sessionId,
    selectedTab,
  });
  handleRequestClose();
};

const handleQuickPayClick = (e, eventTime, handleRequestClose) => {
  e.preventDefault();

  QuickpayModalActions.toggleCalendarQuickpayModal(eventTime);
  handleRequestClose();
};

const handleViewEvent = event => {
  redirectTo({
    path: currentUser().isClient() ? event.path : event.admin_path,
  });
};

const anchorOrigin = () => {
  if (smallScreen()) {
    return {
      horizontal: 'left',
      vertical: 'bottom',
    };
  }
  return {
    horizontal: 'right',
    vertical: 'top',
  };
};

function ViewEventMenuItem({ intl, event }) {
  const isTeamEvent = event?.isTeamEvent();
  const label = isTeamEvent ? '.view_team' : '.view_event';
  return (
    <MenuItem
      key="MenuItem-ViewEvent"
      value="event"
      style={styles.MenuItem}
      onClick={() => handleViewEvent(event)}
    >
      <span style={styles.MenuItemLink}>{t(label, intl, __filenamespace)}</span>
    </MenuItem>
  );
}

function ViewSessionMenuItem({ intl, eventTime, handleRequestClose }) {
  return (
    <MenuItem
      key="MenuItem-ViewSession"
      value="session"
      style={styles.MenuItem}
      onClick={e => handleSessionClick(e, eventTime, handleRequestClose)}
    >
      {t('.view_session', intl, __filenamespace)}
    </MenuItem>
  );
}

function SessionNoteMenuItem({
  intl,
  eventTime,
  handleRequestClose,
  sessions,
}) {
  const sessionId = eventTime.get('session_id');
  const session = sessions.get(sessionId, new Session());
  const sessionNote = session.get('note') || eventTime.get('note', '') || '';
  const itemTitleKey = sessionNote ? '.edit_session_note' : '.add_session_note';

  return (
    <MenuItem
      key="MenuItem-AddSessionNote"
      value="session"
      style={styles.MenuItem}
      onClick={e =>
        handleSessionClick(e, eventTime, handleRequestClose, 'notes')
      }
    >
      {t(itemTitleKey, intl, __filenamespace)}
    </MenuItem>
  );
}

function ContextMenu(props) {
  const {
    eventTime,
    event,
    open,
    anchorEl,
    handleRequestClose,
    sessionDataStore,
  } = props;
  const intl = useIntl();
  const { sessions } = sessionDataStore;
  const shouldShowQuickPay =
    eventTime.debit_source_ids.size === 1 &&
    eventTime.client_status.get('pending').size === 1;
  const isEventsRestricted = currentUser().isRestricted('events');
  const menuItems = [];

  if (shouldShowQuickPay) {
    menuItems.push(
      <MenuItem
        key="MenuItem-QuickPay"
        value="quickPay"
        style={styles.MenuItem}
        onClick={e => handleQuickPayClick(e, eventTime, handleRequestClose)}
      >
        {t('.quick_pay', intl, __filenamespace)}
      </MenuItem>
    );
  }

  if (!isEventsRestricted) {
    menuItems.push(
      <ViewEventMenuItem key="MenuItem-ViewEvent" intl={intl} event={event} />
    );
  }

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin()}
      onClose={handleRequestClose}
      open={open}
    >
      <>
        {menuItems}
        <ViewSessionMenuItem
          key="MenuItem-ViewSession"
          intl={intl}
          eventTime={eventTime}
          handleRequestClose={handleRequestClose}
        />
        {!isEventsRestricted && (
          <SessionNoteMenuItem
            key="MenuItem-AddSessionNote"
            intl={intl}
            eventTime={eventTime}
            handleRequestClose={handleRequestClose}
            sessions={sessions}
          />
        )}
      </>
    </Popover>
  );
}

export default compose(
  altContainer({
    stores: {
      sessionDataStore: SessionDataStore,
    },
  })
)(ContextMenu);

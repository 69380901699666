import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography, Grid, Checkbox } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils';

import { STAFF_PERMISSIONS, PERMISSION_KEYS } from 'shared/records/Staff.jsx';

import PermissionSelector from './PermissionSelector.jsx';

function EditPermissions({
  permissions,
  reportsPermissions,
  onPermissionChange,
  onReportFullAccess,
  onReportPartialAccess,
  onReportRestrictedAccess,
}) {
  const intl = useIntl();
  const reportsPermission = permissions.find(
    p => p.key === PERMISSION_KEYS.REPORTS
  )?.value;
  const [reportPermission, setReportPermission] = useState(reportsPermission);

  const handleChange = e => {
    if (e.target.name === PERMISSION_KEYS.REPORTS) {
      setReportPermission(e.target.value);

      if (e.target.value === STAFF_PERMISSIONS.MANAGE) {
        onReportFullAccess();
      }

      if (e.target.value === STAFF_PERMISSIONS.RESTRICTED) {
        onReportRestrictedAccess();
      }

      return;
    }

    onPermissionChange(e);
  };

  return (
    <Grid container spacing={1}>
      {permissions.map(permission => (
        <Grid
          item
          container
          xs={12}
          key={permission.key}
          alignItems="center"
          justify="space-between"
        >
          <Grid item xs={7}>
            <Typography variant="body1" className="staff-permissions__name">
              {permission.name}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <PermissionSelector
              permission={
                permission.key !== PERMISSION_KEYS.REPORTS
                  ? permission
                  : {
                      ...permission,
                      value: reportPermission || permission.value,
                    }
              }
              onChange={handleChange}
            />
          </Grid>
          {permission.key === PERMISSION_KEYS.REPORTS &&
            reportPermission === STAFF_PERMISSIONS.VIEW && (
              <Grid item container xs={12} direction="column">
                <Grid item xs={12}>
                  <Typography variant="body1">
                    {t('.select_reports', intl, __filenamespace)}
                  </Typography>
                </Grid>
                {reportsPermissions.map(p => (
                  <Grid item container key={p.key} xs={12} alignItems="center">
                    <Grid item xs={2}>
                      <Checkbox
                        name={p.key}
                        checked={p.value === 'view'}
                        onChange={(e, checked) => {
                          onReportPartialAccess({
                            report: p.key,
                            value: checked,
                          });
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      className="staff-permissions__report-name"
                    >
                      <Typography>{p.name}</Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )}
        </Grid>
      ))}
    </Grid>
  );
}

EditPermissions.propTypes = {
  permissions: PropTypes.array.isRequired,
  reportsPermissions: PropTypes.array.isRequired,
  onPermissionChange: PropTypes.func.isRequired,
  onReportFullAccess: PropTypes.func.isRequired,
  onReportPartialAccess: PropTypes.func.isRequired,
  onReportRestrictedAccess: PropTypes.func.isRequired,
};

export default memo(EditPermissions);

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { AutomationTemplateDescriptionSource, EventSource } from 'sources';
import { currentUser, hasAdminPermission } from 'shared/utils/UserUtils.jsx';
import AdminPageActions from './actions';

export const CLIENTS_TAB = 0;
export const PAYMENTS_TAB = 1;
export const PAYMENT_PLANS_TAB = 2;
export const SCHEDULE_TAB = 3;
export const COMPLETED_SESSIONS_TAB = 4;
export const STAFF_TAB = 5;
export const RESPONSES_TAB = 6;
export const WAITLIST_TAB = 7;

// Note: due to conditional tabs, this needs to be in the same order
// as the tabs are displayed on the page.
const ALL_TABS = [
  CLIENTS_TAB,
  PAYMENTS_TAB,
  PAYMENT_PLANS_TAB,
  SCHEDULE_TAB,
  COMPLETED_SESSIONS_TAB,
  STAFF_TAB,
  RESPONSES_TAB,
  WAITLIST_TAB,
];

export const HASH_TO_TAB = {
  '#clients': CLIENTS_TAB,
  '#payments': PAYMENTS_TAB,
  '#payment_plans': PAYMENT_PLANS_TAB,
  '#schedule': SCHEDULE_TAB,
  '#completed_sessions': COMPLETED_SESSIONS_TAB,
  '#staff': STAFF_TAB,
  '#registration_responses': RESPONSES_TAB,
  '#waitlist': WAITLIST_TAB,
};

const TAB_TO_HASH = {
  [CLIENTS_TAB]: '#clients',
  [PAYMENTS_TAB]: '#payments',
  [PAYMENT_PLANS_TAB]: '#payment_plans',
  [SCHEDULE_TAB]: '#schedule',
  [COMPLETED_SESSIONS_TAB]: '#completed_sessions',
  [STAFF_TAB]: '#staff',
  [RESPONSES_TAB]: '#registration_responses',
  [WAITLIST_TAB]: '#waitlist',
};

const CONDITIONAL_TABS = [PAYMENTS_TAB, PAYMENT_PLANS_TAB, WAITLIST_TAB];

class AdminPageStore extends UpperHandStore {
  constructor() {
    super();

    this.showTab = {};
    CONDITIONAL_TABS.forEach(ct => {
      this.showTab[ct] = HASH_TO_TAB[window.location.hash] === ct;
    });

    this.selectTabFromUrl();

    this.eventId = null;
    this.revenue = 0;
    this.loading = false;

    this.setShowPaymentsAndPaymentPlans();

    this.bindListeners({
      eventFetchSuccess: AdminPageActions.eventFetchSuccess,
      eventFetchError: AdminPageActions.eventFetchError,
      eventRevenueFetchSuccess: AdminPageActions.eventRevenueFetchSuccess,
      eventRevenueFetchError: AdminPageActions.eventRevenueFetchError,
      mounted: AdminPageActions.mounted,
      fetchTeam: AdminPageActions.fetchTeam,
      tabSelected: AdminPageActions.tabSelected,
      automationTemplateDescriptionListSuccess:
        AdminPageActions.automationTemplateDescriptionListSuccess,
      automationTemplateDescriptionListError:
        AdminPageActions.automationTemplateDescriptionListError,
      selectTabFromUrl: AdminPageActions.selectTabFromUrl,
    });
  }

  mounted(id) {
    this.eventId = id;

    // get the Event
    this.getEvent(this.eventId);
    this.selectTabFromUrl();
  }

  getEvent(id) {
    this.loading = true;

    EventSource.fetch({
      id,
      success: AdminPageActions.eventFetchSuccess,
      error: AdminPageActions.eventFetchError,
    });

    if (!currentUser().isManager() && hasAdminPermission()) {
      EventSource.fetchRevenue({
        id: this.eventId,
        success: AdminPageActions.eventRevenueFetchSuccess,
        error: AdminPageActions.eventRevenueFetchError,
      });
    }
  }

  eventFetchSuccess(event) {
    this.loading = false;
    this.showTab[WAITLIST_TAB] = event && event.allow_waitlist;
    this.setShowPaymentsAndPaymentPlans(); // only setting it in constructor results in lost values

    if (hasAdminPermission() && event.isFixedSchedule()) {
      AutomationTemplateDescriptionSource.list({
        params: {
          event_ids: [this.eventId],
        },
        success: AdminPageActions.automationTemplateDescriptionListSuccess,
        error: AdminPageActions.automationTemplateDescriptionListError,
      });
    } else {
      this.showTab[PAYMENT_PLANS_TAB] = false;
      this.selectTabFromUrl();
    }
  }

  fetchTeam(id) {
    this.eventId = id;
    this.selectTabFromUrl();
    this.teamFetchSuccess();
    if (!currentUser().isManager() && hasAdminPermission()) {
      EventSource.fetchRevenue({
        id: this.eventId,
        success: AdminPageActions.eventRevenueFetchSuccess,
        error: AdminPageActions.eventRevenueFetchError,
      });
    }
  }

  teamFetchSuccess() {
    this.loading = false;
    this.showTab[WAITLIST_TAB] = false;
    this.setShowPaymentsAndPaymentPlans(); // only setting it in constructor results in lost values

    if (hasAdminPermission()) {
      AutomationTemplateDescriptionSource.list({
        params: {
          event_ids: [this.eventId],
        },
        success: AdminPageActions.automationTemplateDescriptionListSuccess,
        error: AdminPageActions.automationTemplateDescriptionListError,
      });
    } else {
      this.showTab[PAYMENT_PLANS_TAB] = false;
      this.selectTabFromUrl();
    }
  }

  eventRevenueFetchSuccess({ revenue }) {
    this.revenue = revenue;
  }

  eventRevenueFetchError(...args) {
    this.notifyError('error fetching event revenue', args);
  }

  eventFetchError(...args) {
    this.loading = false;
    this.notifyError('error fetching event', args);
  }

  tabSelected(newTab) {
    if (ALL_TABS.includes(newTab)) {
      this.selectedTab = newTab;
      window.location.hash = TAB_TO_HASH[this.addConditionalTabs(newTab)];
    }
  }

  removeConditionalTabs(index) {
    let tab = index;

    // Subtract other conditional tabs to keep us on the right tab.
    // If this does not work, can take from the addConditionalTabs algorithm.
    CONDITIONAL_TABS.forEach(ct => {
      if (ct < index && !this.showTab[ct]) tab -= 1;
    });

    return tab;
  }

  addConditionalTabs(index) {
    const nonConditionalOrShownTabs = ALL_TABS.filter(
      t => !CONDITIONAL_TABS.includes(t) || this.showTab[t]
    );

    return nonConditionalOrShownTabs[index];
  }

  // Since we store computed properties in this store, no need to accept event object.
  selectTabFromUrl() {
    const originalTab = HASH_TO_TAB[window.location.hash];
    const adjustedTab = this.removeConditionalTabs(originalTab);

    if (
      (originalTab === PAYMENTS_TAB && !this.showTab[PAYMENTS_TAB]) ||
      (originalTab === PAYMENT_PLANS_TAB && !this.showTab[PAYMENT_PLANS_TAB]) ||
      (originalTab === WAITLIST_TAB && !this.showTab[WAITLIST_TAB]) ||
      originalTab === undefined
    ) {
      this.selectedTab = CLIENTS_TAB;
    } else {
      this.selectedTab = adjustedTab;
    }
  }

  automationTemplateDescriptionListSuccess({
    automation_template_descriptions: automationTemplateDescriptions,
  }) {
    this.showTab[PAYMENT_PLANS_TAB] = automationTemplateDescriptions.some(
      atd => atd.isPaymentPlan
    );
    this.selectTabFromUrl();
  }

  automationTemplateDescriptionListError(...args) {
    this.notifyError('error listing automation template descriptions', args);
  }

  setShowPaymentsAndPaymentPlans() {
    const computedPermission =
      !currentUser().isRestricted('report_balances') &&
      (hasAdminPermission() || currentUser().isStaffMember());

    this.showTab[PAYMENTS_TAB] = computedPermission;
    this.showTab[PAYMENT_PLANS_TAB] = computedPermission;
  }
}

export default alt.createStore(AdminPageStore, 'AdminPageStore');

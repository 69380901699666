import * as React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { List, Set, Map } from 'immutable';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Card, Grid, Typography } from '@upperhand/playmaker';

import StaffSection from 'containers/classes/classesList/components/Schedules/StaffSection.jsx';
import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import ResourceIcon from 'shared/components/icons/Resource.jsx';
import WarningIcon from 'containers/classes/classesList/components/Sessions/WarningIcon.jsx';

import Schedule from 'shared/records/Schedule';

import { messageId, t } from 'shared/utils/LocaleUtils';
import { weekdayLabels } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';

import EventScheduleCancelActions from 'containers/eventScheduleCancellation/Actions';
import EventSessionCreationActions from 'containers/eventSessionCreation/Actions';

const styles = {
  cancelledIcon: {
    display: 'inline-block',
    marginRight: 10,
    marginTop: 5,
  },
  icon: {
    height: 16,
    width: 16,
    marginRight: 8,
  },
};

function StartAtTime({ daytimes }) {
  const differentStartAtTimes = List(Object.values(daytimes))
    .flatMap(times => times.map(time => time.start_time))
    .toSet();
  return differentStartAtTimes.size === 1 ? (
    <FormattedMessage
      id={messageId('.start_at', __filenamespace)}
      values={{
        startsAt: moment(differentStartAtTimes.first(), 'HH:mm').format(
          'h:mma'
        ),
      }}
    />
  ) : (
    <FormattedMessage id={messageId('.multiple_start_at', __filenamespace)} />
  );
}

function ScheduleRepeatDaily({ interval }) {
  return interval === 1 ? (
    <FormattedMessage id={messageId('.every_day_repeat', __filenamespace)} />
  ) : (
    <FormattedMessage
      id={messageId('.daily_repeat', __filenamespace)}
      values={{ interval }}
    />
  );
}

function ScheduleRepeat({ daytimes, frequency, interval }) {
  if (frequency === 'daily') {
    return <ScheduleRepeatDaily interval={interval} />;
  }
  if (Object.keys(daytimes).length === 7) {
    return <FormattedMessage id={messageId('.daily', __filenamespace)} />;
  }
  return Object.keys(daytimes)
    .map(key => weekdayLabels.get(key))
    .join(', ');
}

function ScheduleDate({ startDate, endDate }) {
  if (startDate === endDate) {
    return moment(startDate).format('MMM DD');
  }
  return (
    <FormattedMessage
      id={messageId('.dates', __filenamespace)}
      values={{
        start: moment(startDate).format('MMM DD'),
        end: endDate ? moment(endDate).format('MMM DD') : 'Indefinite',
      }}
    />
  );
}

function ScheduleCard({ staff = Map(), schedule = new Schedule(), intl }) {
  const isPermittedEdit = currentUser().isPermittedEdit('events');
  const menuOptions = [
    {
      label: t('.edit_schedule', intl, __filenamespace),
      onClick: () => EventSessionCreationActions.editSchedule({ schedule }),
      visible: isPermittedEdit,
    },
    {
      label: t('.cancel_schedule', intl, __filenamespace),
      onClick: () =>
        EventScheduleCancelActions.toggleCancelModal({
          scheduleItem: schedule,
        }),
      visible: schedule.status !== 'cancelled' && isPermittedEdit,
    },
  ].filter(item => item.visible);

  const { availability_schedule: availabilitySchedule } = schedule;
  const {
    start_date: startDate,
    end_date: endDate,
    daytimes,
    frequency,
    interval,
  } = availabilitySchedule;

  return (
    <Card
      contentDirection="row"
      menuOptions={menuOptions.length === 0 ? null : menuOptions}
    >
      <Grid container alignItems="center" xs={12} spacing={1}>
        <Grid item alignItems="center" xs={6} md={2} spacing={1}>
          <FlexBoxCenter>
            {schedule.status === 'cancelled' && (
              <>
                <span data-tip style={styles.cancelledIcon}>
                  <WarningIcon height={17} width={20} />
                </span>
                <ReactTooltip className="uh-tooltip" effect="solid">
                  {t('.status_cancelled', intl, __filenamespace)}
                </ReactTooltip>
              </>
            )}
            <Typography variant="subtitle2" display="inline">
              <ScheduleDate startDate={startDate} endDate={endDate} />
            </Typography>
          </FlexBoxCenter>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography>
            <ScheduleRepeat
              daytimes={daytimes}
              frequency={frequency}
              interval={interval}
            />
          </Typography>
        </Grid>
        <Grid item xs={6} md={1}>
          <StartAtTime daytimes={daytimes} />
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography>
            {schedule.location ? schedule.location.name : ''}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <StaffSection
            intl={intl}
            staffIds={Set(schedule.customer_user_ids)}
            staff={staff}
          />
        </Grid>
        <Grid item xs={12} md={1} alignItems="center" spacing={1}>
          <FlexBoxCenter>
            <ResourceIcon style={styles.icon} />
            <Typography>{schedule.resource_ids.length}</Typography>
          </FlexBoxCenter>
        </Grid>
      </Grid>
    </Card>
  );
}

ScheduleCard.propTypes = {
  intl: PropTypes.object.isRequired,
  staff: PropTypes.instanceOf(Map),
  schedule: PropTypes.instanceOf(Schedule),
};

export default injectIntl(ScheduleCard);

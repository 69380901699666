import TeamDetailPage from 'containers/events/admin/admin_page/TeamDetailPage.jsx';
import * as React from 'react';
import { Route } from 'react-router-dom';

import Page from 'routes/Page.jsx';
import AuthorizedPageWrapper from 'routes/helpers/AuthorizedPage.jsx';

import {
  isClient,
  isUnauthorized,
  isStaffMemberEventsEdit,
  isStaffMemberEvents,
} from 'routes/helpers/routePermissions';
import TeamMgmtEditing from 'team_mgmt/editing/components/TeamMgmtEditing.jsx';

const teamsRoutes = () => (
  <>
    <Route
      path=":customer_id/teams/new"
      element={
        <AuthorizedPageWrapper allowedRoles={[isStaffMemberEventsEdit]}>
          <Page>
            <TeamMgmtEditing />
          </Page>
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path=":customer_id/teams/:teamSlug/edit"
      element={
        <AuthorizedPageWrapper allowedRoles={[isStaffMemberEventsEdit]}>
          <Page>
            <TeamMgmtEditing />
          </Page>
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path=":customer_id/teams/:eventSlug"
      element={
        <AuthorizedPageWrapper
          redirectWhenLoggedOut={false}
          allowedRoles={[isStaffMemberEvents, isClient, isUnauthorized]}
        >
          <Page>
            <TeamDetailPage isClient />
          </Page>
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path=":customer_id/teams/:eventSlug/admin"
      element={
        <AuthorizedPageWrapper allowedRoles={[isStaffMemberEvents]}>
          <Page>
            <TeamDetailPage />
          </Page>
        </AuthorizedPageWrapper>
      }
    />
  </>
);

export default teamsRoutes;

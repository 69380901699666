import * as React from 'react';
import { Route } from 'react-router-dom';

import AdminPage from 'containers/events/admin/admin_page/AdminPage.jsx';
import EventMgmtDisplay from 'event_mgmt/display/components/EventMgmtDisplay.jsx';
import EventMgmtEditing from 'event_mgmt/editing/components/EventMgmtEditing.jsx';
import Page from 'routes/Page.jsx';
import AuthorizedPageWrapper from 'routes/helpers/AuthorizedPage.jsx';

import {
  isUnauthorized,
  isClient,
  isStaffMemberEventsEdit,
  isStaffMemberEvents,
} from 'routes/helpers/routePermissions';

const eventsRoutes = () => (
  <>
    <Route
      path=":customer_id/events/new"
      element={
        <AuthorizedPageWrapper allowedRoles={[isStaffMemberEventsEdit]}>
          <Page>
            <EventMgmtEditing />
          </Page>
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path=":customer_id/events/:eventSlug/edit"
      element={
        <AuthorizedPageWrapper allowedRoles={[isStaffMemberEventsEdit]}>
          <Page>
            <EventMgmtEditing />
          </Page>
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path=":customer_id/events/:eventSlug"
      element={
        <AuthorizedPageWrapper
          redirectWhenLoggedOut={false}
          allowedRoles={[isStaffMemberEvents, isClient, isUnauthorized]}
        >
          <Page>
            <EventMgmtDisplay />
          </Page>
        </AuthorizedPageWrapper>
      }
    />
    <Route
      path=":customer_id/events/:eventSlug/admin"
      element={
        <AuthorizedPageWrapper allowedRoles={[isStaffMemberEvents]}>
          <Page>
            <AdminPage />
          </Page>
        </AuthorizedPageWrapper>
      }
    />
  </>
);

export default eventsRoutes;

import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { Map } from 'immutable';
import { Typography, Button, Grid } from '@upperhand/playmaker';

import EditPermissions from 'contacts/index/components/StaffPermissions/EditPermissions.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import StaffDetailsDrawerStore from 'contacts/shared/stores/StaffDetailsDrawerStore.jsx';

import StaffDetailsDrawerActions from 'contacts/shared/actions/StaffDetailsDrawerActions.jsx';

import {
  PERMISSION_KEYS,
  STAFF_PERMISSIONS,
  TWO_CONDITIONS_PERMISSION_KEY,
} from 'shared/records/Staff.jsx';

import './styles.scss';

const getReportsPermissionValue = permission => {
  const fullAccess = permission.every(p => p === STAFF_PERMISSIONS.VIEW);
  const partialAccess = permission.some(p => p === STAFF_PERMISSIONS.VIEW);

  if (fullAccess) {
    return STAFF_PERMISSIONS.MANAGE;
  }

  if (partialAccess) {
    return STAFF_PERMISSIONS.VIEW;
  }

  return STAFF_PERMISSIONS.RESTRICTED;
};

function StaffDetailsDrawerPermissions({ staffDetailsDrawerStore = {} }) {
  const intl = useIntl();
  const [isEditing, setIsEditing] = useState(false);
  const { staffPermissions } = staffDetailsDrawerStore;
  const permissions = staffPermissions
    .map((permission, permissionName) => ({
      key: permissionName,
      name: permissionName.replace(/_/g, ' '),
      value:
        permissionName === 'reports'
          ? getReportsPermissionValue(permission)
          : permission,
    }))
    .sort((a, b) => a.name.localeCompare(b.name))
    .toArray();
  const reportsPermissions = staffPermissions
    .get('reports', Map())
    .map((permission, permissionName) => ({
      key: permissionName,
      name: permissionName.replace('report', '').replace(/_/g, ' '),
      value: permission,
    }))
    .toArray();

  const handlePermissionChange = e => {
    const { name, value } = e.target;

    StaffDetailsDrawerActions.updatePermission({
      permission: name,
      value,
    });
  };

  return (
    <div className="staff-permissions">
      <Grid
        container
        alignItems="center"
        justify="space-between"
        className="staff-permissions__header"
      >
        <Grid item xs={6}>
          <Typography variant="h5">
            {t(
              isEditing ? '.edit_permissions' : '.all_permissions',
              intl,
              __filenamespace
            )}
          </Typography>
        </Grid>
        {!isEditing && (
          <Grid item xs={6} container justify="flex-end">
            <Button
              rounded
              icon="edit"
              type="tertiary"
              classes={{ root: 'staff-permissions__edit-btn' }}
              onClick={() => setIsEditing(true)}
            />
          </Grid>
        )}
        {isEditing && (
          <Grid item container spacing={1} xs={6} justify="flex-end">
            <Grid item>
              <Button
                type="tertiary"
                classes={{ root: 'staff-permissions__cancel-btn' }}
                onClick={() => {
                  setIsEditing(false);
                  StaffDetailsDrawerActions.resetPermissions();
                }}
              >
                {t('.cancel', intl, __filenamespace)}
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="primary"
                classes={{ root: 'staff-permissions__save-btn' }}
                onClick={() => {
                  setIsEditing(false);
                  StaffDetailsDrawerActions.savePermissions();
                }}
              >
                {t('.save', intl, __filenamespace)}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
      {!isEditing ? (
        <Grid container spacing={1}>
          {permissions.map(permission => (
            <Grid
              item
              container
              alignItems="center"
              justify="space-between"
              key={permission.key}
            >
              <Grid item>
                <Typography variant="body1" className="staff-permissions__name">
                  {permission.name}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body1"
                  className={clsx(
                    'staff-permissions__value',
                    `staff-permissions__${permission.value}`,
                    TWO_CONDITIONS_PERMISSION_KEY.includes(permission.key) &&
                      permission.value === STAFF_PERMISSIONS.VIEW &&
                      'staff-permissions__manage'
                  )}
                >
                  {permission.key === PERMISSION_KEYS.REPORTS &&
                    t(`.report_${permission.value}`, intl, __filenamespace)}
                  {permission.key !== PERMISSION_KEYS.REPORTS &&
                    t(`.${permission.value}`, intl, __filenamespace)}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      ) : (
        <EditPermissions
          permissions={permissions}
          reportsPermissions={reportsPermissions}
          onPermissionChange={handlePermissionChange}
          onReportFullAccess={StaffDetailsDrawerActions.reportFullAccess}
          onReportPartialAccess={StaffDetailsDrawerActions.reportPartialAccess}
          onReportRestrictedAccess={
            StaffDetailsDrawerActions.reportRestrictedAccess
          }
        />
      )}
    </div>
  );
}

StaffDetailsDrawerPermissions.propTypes = {
  staffDetailsDrawerStore: PropTypes.object,
};

export default compose(
  memo,
  altContainer({
    stores: {
      staffDetailsDrawerStore: StaffDetailsDrawerStore,
    },
  })
)(StaffDetailsDrawerPermissions);

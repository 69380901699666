import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { Grid, Typography, Button } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils';
import { currentUser } from 'shared/utils/UserUtils.jsx';

function AnswerHeader({ onEditResponse = () => {} }) {
  const intl = useIntl();
  const isPermittedEdit = currentUser().isPermittedEdit('events');

  return (
    <>
      <Grid item container direction="row" alignItems="center" xs={6}>
        <Typography
          variant="fieldLabel"
          className="registration-responses__answer-header"
        >
          {t('.registration_fields', intl, __filenamespace)}
          {isPermittedEdit && (
            <Button
              rounded
              type="tertiary"
              icon="edit"
              size="1x"
              onClick={onEditResponse}
            />
          )}
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={6}
        justify="flex-end"
        className="registration-responses__answer-header"
      >
        {t('.required_fields', intl, __filenamespace)}
      </Grid>
    </>
  );
}

export default memo(AnswerHeader);

/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import UnsignedWaiver from 'shared/components/icons/UnsignedWaiver.jsx';
import Gift from 'shared/components/icons/Gift.jsx';
import UserAvatar from 'shared/components/_UserAvatar.jsx';

import CalendarActions from 'calendar/actions/CalendarActions.jsx';

import { FlexBox, FlexBoxJustify } from 'shared/components/FlexBox.jsx';

import { messageId } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';

function NewUserIndicator({ membership }) {
  return (
    <div>
      <span style={{ color: 'var(--color-green)', fontWeight: '500' }}>
        <FormattedMessage id={messageId('.new_user', __filenamespace)} />
      </span>
      {membership && (
        <span>
          <span style={{ margin: '0 3px' }}>&bull;</span>
          {membership.name}
        </span>
      )}
    </div>
  );
}

const checkInAttendance = (e, eventTime, cuid) => {
  e.stopPropagation();
  CalendarActions.checkIn({
    sessionId: eventTime.session_id,
    customerUserId: cuid,
  });
};

const checkOutAttendance = (e, eventTime, cuid) => {
  e.stopPropagation();
  CalendarActions.checkOut({
    sessionId: eventTime.session_id,
    customerUserId: cuid,
  });
};

const noShowHandler = (e, eventTime, cuid) => {
  e.stopPropagation();
  CalendarActions.noShowHandler({
    sessionId: eventTime.session_id,
    customerUserId: cuid,
  });
};

function AttendanceButtonWrapper({ disabled, onClick, children }) {
  const isPermittedEdit = currentUser().isPermittedEdit('calendar');

  if (!isPermittedEdit) return null;

  return (
    <IconButton onClick={onClick} disabled={disabled}>
      {children}
    </IconButton>
  );
}

function CheckInButton({ disabled, eventTime, clientId }) {
  const isPermittedEdit = currentUser().isPermittedEdit('calendar');

  if (!isPermittedEdit) return null;

  return (
    <AttendanceButtonWrapper
      disabled={disabled}
      onClick={e => checkInAttendance(e, eventTime, clientId)}
    >
      <CheckCircleIcon />
    </AttendanceButtonWrapper>
  );
}

function NoShowButton({ disabled, eventTime, clientId, isNoShow }) {
  const isPermittedEdit = currentUser().isPermittedEdit('calendar');

  if (!isPermittedEdit) return null;

  return (
    <AttendanceButtonWrapper
      disabled={disabled}
      onClick={e => noShowHandler(e, eventTime, clientId)}
    >
      {isNoShow ? (
        <RemoveCircleIcon
          sx={{
            color: uhColors.warning,
          }}
        />
      ) : (
        <RemoveCircleIcon />
      )}
    </AttendanceButtonWrapper>
  );
}

function NoShowButtonCancel({ disabled, eventTime, clientId, isNoShow }) {
  const isPermittedEdit = currentUser().isPermittedEdit('calendar');

  if (!isPermittedEdit) return null;

  return (
    <AttendanceButtonWrapper
      disabled={disabled}
      onClick={e => checkOutAttendance(e, eventTime, clientId)}
    >
      {isNoShow ? (
        <RemoveCircleIcon
          sx={{
            color: uhColors.warning,
          }}
        />
      ) : (
        <RemoveCircleIcon
          sx={{
            backgroundColor: uhColors.icons.warn,
            color: isNoShow ? uhColors.white : uhColors.lightGrey,
          }}
        />
      )}
    </AttendanceButtonWrapper>
  );
}

function CheckOutButton({ disabled, eventTime, clientId }) {
  const isPermittedEdit = currentUser().isPermittedEdit('calendar');

  if (!isPermittedEdit) return null;

  return (
    <AttendanceButtonWrapper
      disabled={disabled}
      onClick={e => checkOutAttendance(e, eventTime, clientId)}
    >
      <CheckCircleIcon sx={{ color: uhColors.green }} />
    </AttendanceButtonWrapper>
  );
}

export function UserIndicator({ client, hovering, eventTime }) {
  const {
    attendance_customer_user_ids: acuids,
    no_show_customer_user_ids: nscuids,
  } = eventTime;
  const isStaff = currentUser().isStaff();
  const isCoach = currentUser().isCoach();
  const checkedIn = acuids.find(id => id === client.id);
  const isNoShow = nscuids.some(id => id === client.id);
  const buttonProps = {
    disabled: !hovering,
    eventTime,
    clientId: client.id,
  };
  return (
    (isStaff || isCoach) && (
      <FlexBox style={{ gap: '5px' }}>
        {isNoShow ? (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <NoShowButtonCancel {...buttonProps} isNoShow={isNoShow} />
        ) : (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <NoShowButton {...buttonProps} isNoShow={isNoShow} />
        )}
        {checkedIn ? (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <CheckOutButton {...buttonProps} />
        ) : (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <CheckInButton {...buttonProps} />
        )}
      </FlexBox>
    )
  );
}

export function SingleAttendee({ client, membership, handleAttendeesClick }) {
  const isInstructor = currentUser().isInstructor();
  return (
    <div>
      <FlexBoxJustify style={{ alignItems: 'flex-start' }}>
        <FlexBox
          style={{
            alignItems: 'center',
          }}
        >
          <UserAvatar user={client} size={24} />
          <Box onClick={handleAttendeesClick}>
            <Typography
              className={isInstructor ? '' : 'event-card__attendee-link'}
            >
              {client.name()}
            </Typography>
            {client.get('is_new_user') ? (
              <NewUserIndicator membership={membership} />
            ) : (
              membership?.name
            )}
          </Box>
        </FlexBox>
        <FlexBox style={{ alignItems: 'flex-end' }}>
          <FlexBox>
            {client.isTodayBirthDay() && (
              <Gift color="#4AD669" style={{ margin: 8, height: 21 }} />
            )}
            {!client.get('has_accepted_terms') &&
              !client.get('managed_by_id') && (
                <UnsignedWaiver style={{ margin: 8 }} />
              )}
          </FlexBox>
        </FlexBox>
      </FlexBoxJustify>
    </div>
  );
}
